import React, { Component } from 'react';
import UserDashbordSidebar from '../sideBar';
import MonthPickerInput from 'react-month-picker-input';
import axios from 'axios';
import Moment from 'react-moment';
import { authHeader } from '../../../_helpers';
import Pagination from "react-js-pagination";
import PostCountFunc from '../../post/postCountFunc'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'
import EarningsPostList from './earningsPostList'
import EarningsMonthList from './earningsMonthList'

import PropTypes from 'prop-types';
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

import {
  Link
} from 'react-router-dom';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
require('react-month-picker-input/dist/react-month-picker-input.css');
// require('react-month-picker-input/src/i18n.ts');
const apiUrl = process.env.REACT_APP_API

class Earningsinfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
        post:[],
        activePage: 1,
        totalCount:'',
        pp:12,
        startDate:'',
        endDate:'',
        sortBy:'date',
        showExpand:false,
        userBalance:{}
    }

    this.handelExpand = this.handelExpand.bind(this);

  }
  _isMounted = false;
  _isSelected = false;
  _isShow = false;
  componentWillUnmount() {
    this._isMounted = false
    this._isSelected = false
  }
  componentDidMount(){
    let currentComponent = this;
    currentComponent._isMounted = true;
    // currentComponent.handleSubmitedPosts();
    // let req = axios.get(apiUrl+'/earnings/user/',{headers:authHeader()})
    //     .then(function(response) {
    //         const total = response.data.success.total;
    //         if(currentComponent._isMounted){
    //           currentComponent.setState({ totalCount:total });
    //         }
    //     });
    let req = axios.get(apiUrl+'/userbalance',{headers:authHeader()})
        .then(function(response) {
            const userBalance = response.data.success.balance;
            if(currentComponent._isMounted){
              currentComponent.setState({ userBalance });
            }
        });

  }
  handelExpand(e) {
    var state = e.target.id
    // console.log(state)
    if(state === 'show') {
      this.setState({
        showExpand:true
      })
    } else {
      this.setState({
        showExpand:false
      })
    }

  }
  render() {
    var userBalance = this.state.userBalance
    
    const { t } = this.props;

    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <div>
        <div className="earnings-info-container">
          <MetaTags/>
          <UserDashbordSidebar />
            <div className='earnings-info-box'>

              <div className="wrapper">
                <div className="right-section">
                  <div className="section-box">
                    <div className="title">{t('earningsPage.availableBalanceText')}</div>
                    <div className="price">{parseInt((userBalance.balance * 100) / 100 , 0) + ' ֏'}</div>
                    {this.state.showExpand ?
                      <div className="wrap-box">
                        <div className="item">
                          <div className="title">{t('earningsPage.viewCountText')}</div>
                          <div className="price">{userBalance.views}</div>
                        </div>
                        <div className="item">
                          <div className="title">{t('earningsPage.totlaBalanceText')}</div>
                          <div className="price">{parseInt((userBalance.total * 100) / 100 , 0) + ' ֏'}</div>
                        </div>
                      </div>
                      :
                      ''
                    }
                    <div id={this.state.showExpand ? 'hide' : 'show'} onClick={this.handelExpand}  className="expand">{this.state.showExpand ? t('earningsPage.hideButtonText') : t('earningsPage.moreButtonText')}</div>
                    <div className="row">
                      {userBalance.balance !== undefined && userBalance.balance > 0 ?
                        <Link className="button" to={`/${langCode}/user-dashbord/earnings/withdraw`}>{t('earningsPage.withDrawButtonText')}</Link>
                      :
                        <div className="button">{t('earningsPage.withDrawButtonText')}</div>
                      }
                    </div>
                    <Link className="link" to={`/${langCode}/user-dashbord/earnings/history`} >{t('earningsPage.earningsHistoryButtonText')}</Link>
                  </div>
                </div>
              
                <div className="left-section">
                  <EarningsMonthList/>

                  <EarningsPostList/>
                </div>

                
              </div>
            </div>
          </div>
        <Footer/>
      </div>
    );
  }
}

Earningsinfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Earningsinfo);
