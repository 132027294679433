import React, { Component } from 'react';
import axios from 'axios';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'

import {
  Link
} from 'react-router-dom';
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
// import qs from "query-string";
var qs = require('query-string');
const apiUrl = process.env.REACT_APP_API;


class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    // const type=props.type;
    this.state = {
        messageFromServer: '',
        messageColor:'success',
        checkAccountToken :false,
    }

  }
  _isMounted = false;

  componentDidMount(){
      let currentComponent = this;

      let requestUrl = '/users/verify';
      const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      let postdata = {
          token: query.t
        };

        axios.post(apiUrl+requestUrl,qs.stringify(postdata), {
          headers:{'lang' : this.props.match.params.lang}
        })
        .then(function(response) {
                currentComponent.setState({
                    messageFromServer: response.data.message
                });
                if(response.data.code === 200){
                  currentComponent.setState({
                      checkAccountToken: true
                  });
                }
                //console.log(response.data)
        });
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  render() {
    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    return (
      <div>
        <div className="reset-password-container">
          <MetaTags/>
          <div className="table">
            {!this.state.checkAccountToken ?
              <div className="cell">
                <div className="section-title no-margin">
                  {t('verifyAccount.errorTitle')}
                </div>
                <div className="section-description">
                {t('verifyAccount.errorText')}
                </div>
                <Link className="button" to={`/${languageCode}/`}>
                  {t('verifyAccount.backButtonText')}
                </Link>
              </div>
              :
              <div className="cell">
                <div className="section-title no-margin">
                 {t('verifyAccount.thankyouTitle')}
                </div>
                <div className="section-description">
                  {t('verifyAccount.thankyouDescription')}
                </div>
                <Link className="button" to={`/${languageCode}/sign-in`}>{t('verifyAccount.loginButtonText')}</Link>
              </div>
            }
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

VerifyAccount.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(VerifyAccount);