/* eslint-disable react/no-unused-prop-types */
import 'react-dates/initialize';
import React from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import Responsive from 'react-responsive-decorator';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION } from 'react-dates/src/constants';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import 'react-dates/lib/css/_datepicker.css';
// import Responsive from 'react-responsive-decorator';

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: (day) => {
      return false;
  },
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick: ()=> {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick:()=> {},
  onNextMonthClick:()=> {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

class ReserveDays extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props.reservedDaysCount)
    this.state = {
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      blockedDays: props.blockedDays,
      reservedDaysCount:props.reservedDaysCount,
      reservedDays:props.reservedDays,
      reservedDaysFromServer:props.reservedDays,
      type:props.type,
      key:props.randomKey,
      isMobile:false
    };

    this._isMounted = true;
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
    this.isDayHighlighted = this.isDayHighlighted.bind(this);
  }
  componentWillUnmount() {
     this._isMounted = false;
  }
  componentDidMount(){
    this.props.media({ minWidth: 720 }, () => {
      this.setState({
        isMobile: false
      });
    });
    this.props.media({ maxWidth: 720 }, () => {
      this.setState({
        isMobile: true
      });
    });
  }
  onDatesChange({ startDate, endDate }) {
    const blockedDays = this.state.blockedDays;
    var reservedDays = this.state.reservedDays;
    var reservedDaysCount = 0;
    if(endDate != null){
      var duration = moment.duration(endDate.diff(startDate));
      reservedDaysCount = duration.asDays();
      reservedDays = [];
      for (var i = 0; i <= reservedDaysCount; i++) {
          let day = moment(startDate).add(i, 'd').format("YYYY-MM-DD");
          reservedDays.push(day);
      }
      if(blockedDays){
        for (var variable of blockedDays) {
            if(moment(startDate.toDate()).format("YYYY-MM-DD") < moment(variable).format("YYYY-MM-DD") &&
              moment(endDate.toDate()).format("YYYY-MM-DD") > moment(variable).format("YYYY-MM-DD")){
              reservedDaysCount--;
              reservedDays.splice( reservedDays.indexOf(moment(variable).format("YYYY-MM-DD")), 1 );
            }
        }
      }
      reservedDaysCount = reservedDays.length;

    }
    this.props.reservedDaysChange(reservedDays , reservedDaysCount);
    this.setState({ startDate, endDate, reservedDaysCount,reservedDays });

  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    const blockedDays =  nextProps.blockedDays;
    const reservedDays  =  nextProps.reservedDays;
    const focusedInput = START_DATE;
    const key = nextProps.randomKey;
    this.setState({
      blockedDays,
      reservedDays,
      focusedInput,
      key
    })
  }
  isDayBlocked(day){
      if(this.state.blockedDays !== undefined && this.state.blockedDays.indexOf(moment(day).format("YYYY-MM-DD")) !== -1){
        return true;
      }
      return false;
  }
  isDayHighlighted(day){
    if(this.state.reservedDays.length >0 && this.state.reservedDays.indexOf(moment(day).format("YYYY-MM-DD")) !== -1){
      return true;
    }
    return false;
  }
  onFocusChange(focusedInput) {
    //console.log(focusedInput);

    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  render() {
    const { showInputs } = this.props;
    const { focusedInput, startDate, endDate,key ,isMobile} = this.state;
    const props = omit(defaultProps, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'showInputs',
    ]);
    const startDateString = startDate && startDate.format('YYYY-MM-DD');
    const endDateString = endDate && endDate.format('YYYY-MM-DD');
    var orientation=''

    if(isMobile){
      orientation='vertical'
    }else{
      orientation='horizontal'
    }
    return (

      <div style={{ height: '100%' }}>
        {showInputs &&
          <div style={{ marginBottom: 16 }}>
            <input type="text" name="start date" value={startDateString} readOnly />
            <input type="text" name="end date" value={endDateString} readOnly />
          </div>
        }
        {  this._isMounted ?
          <DayPickerRangeController
            {...props}
            orientation={orientation}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            isDayBlocked={this.isDayBlocked}
            isDayHighlighted={this.isDayHighlighted}
            key={key}
            hideKeyboardShortcutsPanel
          />
          :
          ''

        }

      </div>
    );
  }
}

export default Responsive(ReserveDays);
