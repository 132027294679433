import React, { Component } from 'react';

//const apiUrl = process.env.REACT_APP_API;


class PostCountFunc extends Component {
  constructor(props) {
    super(props);
    const postCount = props.viewCount
    this.state = {
      postCount
    }
    this.nFormatter = this.nFormatter.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let postCount = nextProps.viewCount
    this.setState({
      postCount
    })
  }
  nFormatter() {
    var num = this.state.postCount
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
  }

  render() {
    if(this.state.postCount > 0) {
      return (
        <div className="view-count">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 561 382.5">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                  <g id="visibility"><path
                    d="M280.5,0C153,0,43.35,79.05,0,191.25,43.35,303.45,153,382.5,280.5,382.5S517.65,303.45,561,191.25C517.65,79.05,408,0,280.5,0Zm0,318.75c-71.4,0-127.5-56.1-127.5-127.5s56.1-127.5,127.5-127.5S408,119.85,408,191.25,351.9,318.75,280.5,318.75Zm0-204c-43.35,0-76.5,33.15-76.5,76.5s33.15,76.5,76.5,76.5S357,234.6,357,191.25,323.85,114.75,280.5,114.75Z"/></g>
                </g>
              </g>
            </svg>
          </span>
          <span>
            {this.nFormatter()}
          </span>
        </div>
      );
    }else {
      return false
    }
  }
}

export default PostCountFunc;
