import React, { Component } from 'react';

import { authHeader } from '../../../_helpers';
import axios from 'axios';
//import ImageAsset from './image-asset.jpg';
import Pagination from "react-js-pagination";
import AdvertisementItemList from './advertisementListView';

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

const apiUrl = process.env.REACT_APP_API;


class ApprovedAdvertisement extends Component {

  constructor(props) {
    super(props);
    this.state = {
        post:[],
        activePage: 1,
        totalCount:'',
        pp:'',
        render:false
    }
    this.reRender = this.reRender.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
  }
  _isMounted = false;
  reRender(id) {
    this.handleSubmitedPosts();

  }
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidMount(){
      let currentComponent = this;
      currentComponent.handleSubmitedPosts();
  }

  handleSubmitedPosts(pageNumber) {
    let currentComponent = this;
    currentComponent._isMounted = true
    const queryParams = {};
    let curentUser = JSON.parse(localStorage.getItem('user'));
    queryParams.userId = curentUser._id
    queryParams.statusCode = 'A'
    if(pageNumber !== undefined && pageNumber !== '') {
      queryParams.p = pageNumber;
    }

    axios.get(apiUrl+'/commercial/',{params:queryParams,headers:authHeader()})
        .then(function(response) {
            const posts = response.data;
            const page = response.data.pp;
            const total = response.data.total;
            if(currentComponent._isMounted) {
              currentComponent.setState({ post:posts,activePage: pageNumber,totalCount:total,pp:page });
            }
        });
  }
  handlePageChange(pageNumber) {
    this.handleSubmitedPosts(pageNumber);
  }

  render() {
    const { t } = this.props;

    return (
        <div className="approved-advertisement-list">
          <div className="section-title">{t('myAdvertisement.publishedSection.title')}</div>
          <div className="section-description">{t('myAdvertisement.publishedSection.description')}</div>
          {this.state.totalCount > 0 ?
            <div className="approved-advertisement-container">
              {
                this._isMounted ?
                <AdvertisementItemList reRender={this.reRender} cancel='true' posts={this.state.post.advertisements}/>
                :
                ''
              }
              {this.state.pp < this.state.totalCount ?
                <Pagination
                  innerClass="pagination clear"
                  hideFirstLastPages
                  pageRangeDisplayed={5}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pp}
                  totalItemsCount={this.state.totalCount}
                  onChange={this.handlePageChange}
                  nextPageText={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path className="cls-1" d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"/>
                        </g>
                      </g>
                    </svg>
                  }
                  prevPageText={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1" d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"/>
                        </g>
                      </g>
                    </svg>
                  }
                />
                :
                ''
              }
            </div>
            :
            <div className="approved-advertisement-container">
              <div className="error-text">
                {t('myAdvertisement.publishedSection.noResulText')}
              </div>
            </div>
          }
        </div>
    );
  }
}


ApprovedAdvertisement.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(ApprovedAdvertisement);
