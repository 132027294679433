import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import PostItemView from "./postListView";
import Pagination from "react-js-pagination";
// import qs from "query-string";
import { authHeader } from "../../_helpers";
import MetaTags from "../metaTags";
import Footer from "../footerComponent/footer";

import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

var qs = require("query-string");
const apiUrl = process.env.REACT_APP_API;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedPosts: [],
      redirect: false,
      redirectUrl: "",
      activePage: 1,
      sortBy: "any",
      loaded: false
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchedPosts = this.handleSearchedPosts.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
  }
  _isMounted = false;

  componentDidMount() {
    let currentComponent = this;
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const page = query.p && query.p !== "" ? query.p : "";
    const sort = query.d && query.d !== "" ? query.d : "";
    currentComponent.handleSearchedPosts(page, sort);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchedPosts(pageNumber, sortBy) {
    if (
      this.props.location.search !== undefined &&
      this.props.location.search !== ""
    ) {
      let currentComponent = this;
      const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      const queryParams = { s: query.s };
      if (pageNumber !== undefined && pageNumber !== "") {
        queryParams.p = pageNumber;
      }
      if (sortBy !== undefined && sortBy !== "") {
        queryParams.d = sortBy;
      }
      if (authHeader().Authorization !== undefined) {
        var headers = {
          Authorization: authHeader().Authorization,
          lang: this.props.match.params.lang
        };
      } else {
        var headers = {
          lang: this.props.match.params.lang
        };
      }

      var asd = axios
        .get(apiUrl + "/posts/search", {
          params: queryParams,
          headers: headers
        })
        .then(function(response) {
          const searchedPosts = response.data;
          currentComponent.setState({
            searchedPost: searchedPosts,
            activePage: queryParams.p,
            pageNumber,
            sortBy,
            loaded: true
          });

          //window.history.pushState(null, 'search page','/search?'+qs.stringify(queryParams));
        });

      asd.then(x => {
        this.setState({
          loaded: true
        });
        // console.log(2)
      });
    } else {
      let language = JSON.parse(localStorage.getItem("lang"));
      let languageCode = language.code;
      this.setState({
        redirect: true,
        redirectUrl: "/" + languageCode + "/"
      });
    }
  }
  handlePageChange(pageNumber) {
    window.scroll(0, 0);
    this._isMounted = true;
    const sortBy = this.state.sortBy;
    this.handleSearchedPosts(pageNumber, sortBy);
  }

  handleSortBy(e) {
    const sort = e.target.value;
    this._isMounted = true;
    this.handleSearchedPosts(1, sort);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    if (this.state.loaded === false) {
      return false;
    } else {
      const searchedPost = this.state.searchedPost;
      const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      setTimeout(function() {
        var input = document.getElementById("search-input");
        input.value = query.s;
      }, 100);
      const { t } = this.props;

      return (
        <div>
          <div className="searched-post-list">
            <MetaTags />
            <div className="wrapper clear">
              <div className="section-title">
                <span>{t("searchPage.resultText")} </span>
                <span className="suggested-name">{query.s}</span>
              </div>
              <div className="sort-box">
                <span className="name">{t("searchPage.showFiledText")} </span>
                <select onChange={this.handleSortBy} value={this.state.sortBy}>
                  <option value="any">
                    {t("searchPage.showFieldOption1")}
                  </option>
                  <option value="day">
                    {t("searchPage.showFieldOption2")}
                  </option>
                  <option value="week">
                    {t("searchPage.showFieldOption3")}
                  </option>
                  <option value="month">
                    {t("searchPage.showFieldOption4")}
                  </option>
                  <option value="year">
                    {t("searchPage.showFieldOption5")}
                  </option>
                </select>
              </div>
            </div>
            <div className="searched-post-container">
              <div className="wrapper">
                {searchedPost.total > 0 ? (
                  <div>
                    <div className="wrap-box">
                      <PostItemView posts={searchedPost.posts} />
                    </div>
                    {searchedPost.pp < searchedPost.total ? (
                      <Pagination
                        innerClass="pagination clear"
                        hideFirstLastPages
                        pageRangeDisplayed={5}
                        activePage={this.state.activePage}
                        itemsCountPerPage={searchedPost.pp}
                        totalItemsCount={searchedPost.total}
                        onChange={this.handlePageChange}
                        nextPageText={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 243.01 414.68"
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                  className="cls-1"
                                  d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                                />
                              </g>
                            </g>
                          </svg>
                        }
                        prevPageText={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 243.01 414.68"
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                  className="cls-1"
                                  d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                                />
                              </g>
                            </g>
                          </svg>
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="error-text">
                    {t("searchPage.noResultText")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

Search.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(Search);
