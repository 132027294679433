import React, { Component } from 'react';
import UserDashbordSidebar from '../sideBar';
import MonthPickerInput from 'react-month-picker-input';
import axios from 'axios';
import Moment from 'react-moment';
import { authHeader } from '../../../_helpers';
import Pagination from "react-js-pagination";
import PostCountFunc from '../../post/postCountFunc'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

import {
  Link
} from 'react-router-dom';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
require('react-month-picker-input/dist/react-month-picker-input.css');
// require('react-month-picker-input/src/i18n.ts');
const apiUrl = process.env.REACT_APP_API

class EarningsPostList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        post:[],
        activePage: 1,
        totalCount:'',
        pp:12,
        startDate:'',
        endDate:'',
        sortBy:'date',
        showExpand:false,
        userBalance:{}
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
    this.handleResetStartEnd = this.handleResetStartEnd.bind(this);
    this.handelExpand = this.handelExpand.bind(this);
  }
  _isMounted = false;
  _isSelected = false;
  _isShow = false;
  componentWillUnmount() {
    this._isMounted = false
    this._isSelected = false
  }
  componentDidMount(){
    let currentComponent = this;
    currentComponent.handleSubmitedPosts();
    let req = axios.get(apiUrl+'/earnings/user/',{headers:authHeader()})
        .then(function(response) {
          currentComponent._isMounted = true;

            const total = response.data.success.total;
            if(currentComponent._isMounted){
              currentComponent.setState({ totalCount:total });
            }
        });

  }
  handleStartDate(maskedValue) {
    // console.log(maskedValue)
    var startDate = maskedValue.replace('/' , '-')
    this.setState({
      startDate
    })
    var endDate = this.state.endDate
    var sortBy = this.state.sortBy;

    this.handleSubmitedPosts(1,startDate,endDate,sortBy)
    this._isSelected = true;
  }
  handleEndDate(maskedValue) {
    // console.log(maskedValue)
    var endDate = maskedValue.replace('/' , '-')
    this.setState({
      endDate
    })
    var startDate = this.state.startDate
    var sortBy = this.state.sortBy;

    this.handleSubmitedPosts(1,startDate,endDate,sortBy)
    this._isSelected = true;

  }
  handleResetStartEnd() {
    this.setState({
      endDate:'',
      startDate:'',
    })
    var sortBy = this.state.sortBy;
    this.handleSubmitedPosts(1,'','',sortBy)
    this._isSelected = false;
  }
  handleSortBy(e) {
    const sortBy = e.target.value;
    this._isMounted = true;
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    this.setState({
      sortBy
    })
    this.handleSubmitedPosts(1,startDate,endDate,sortBy)
  }
  handleSubmitedPosts(pageNumber,startDate,endDate,sortBy) {
    let currentComponent = this;
    const queryParams = {};
    let PostPerPage = currentComponent.state.pp
    queryParams.limit = PostPerPage;
    queryParams.sortBy = sortBy;

    if(pageNumber !== undefined && pageNumber !== '') {
      queryParams.offset = pageNumber;
    }
    if(startDate !== undefined && startDate !== '') {
      queryParams.startDate = startDate;
    }
    if(endDate !== undefined && endDate !== '') {
      queryParams.endDate = endDate;
    }
    axios.get(apiUrl+'/earnings/user/',{params:queryParams,headers:authHeader()})
        .then(function(response) {
            const posts = response.data.success.earnings;
              currentComponent.setState({ post:posts,activePage: pageNumber });
        });
  }
  handlePageChange(pageNumber) {
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    var sortBy = this.state.sortBy;
    this.handleSubmitedPosts(pageNumber,startDate,endDate,sortBy);
  }
  handelExpand(e) {
    var state = e.target.id
    // console.log(state)
    if(state === 'show') {
      this.setState({
        showExpand:true
      })
    } else {
      this.setState({
        showExpand:false
      })
    }

  }
  render() {
    if(this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    }
    // console.log(this.state.startDate)
    // console.log(this.state.endDate)
    let currentComponent = this
    var userBalance = this.state.userBalance
    // console.log(userBalance)
    
    const { t } = this.props;

    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <React.Fragment>
        <div className="post-list">
          <div className="section-title">
            {t('earningsPage.pageTitle')}
          </div>
          <div className="section-description">{t('earningsPage.pageDescription')}</div>
          <div className="earnings-info-box-container">
                
              <React.Fragment >
                <div className="top clear">
                  <div className="left">
                    <div className="start-date">
                      <span>{t('earningsPage.startDateFieldTtitle')}</span>
                      <MonthPickerInput lang={langCode}
                        closeOnSelect={true}
                        inputProps={{
                          'id':'bubu',
                          'value':this.state.startDate
                        }}
                        i18n={{
                          "monthFormat": "long",
                          "dateFormat": {langCode: "YYYY/MM"},
                          "monthNames": {
                            "hy": [
                              'Հունվար',
                              'Փտրվար',
                              'Մարտ',
                              'Ապրիլ',
                              'Մայիս',
                              'Հունիս',
                              'Հուլիս',
                              'Օգոստոս',
                              'Սեպտեմբեր',
                              'Հոկտեմբեր',
                              'Նոյեմբեր',
                              'Դեկտեմբեր'
                            ],
                            "en" : [
                              'January',
                              'February',
                              'March',
                              'April',
                              'May',
                              'June',
                              'July',
                              'August',
                              'September',
                              'October',
                              'November',
                              'December'
                            ]
                          }
                        }}
                        onChange={function(maskedValue, year, month) {
                          currentComponent.handleStartDate(maskedValue)
                        }}
                        />
                    </div>
                    <div className="end-date">
                      <span>{t('earningsPage.endDateFieldTtitle')}</span>
                      <MonthPickerInput lang={langCode}
                        closeOnSelect={true}
                        inputProps={{
                          'name':'mountPicker',
                          'value':this.state.endDate
                        }}
                        i18n={{
                          "monthFormat": "long",
                          "dateFormat": {langCode: "YYYY/MM"},
                          "monthNames": {
                            "hy": [
                              'Հունվար',
                              'Փտրվար',
                              'Մարտ',
                              'Ապրիլ',
                              'Մայիս',
                              'Հունիս',
                              'Հուլիս',
                              'Օգոստոս',
                              'Սեպտեմբեր',
                              'Հոկտեմբեր',
                              'Նոյեմբեր',
                              'Դեկտեմբեր'
                            ],
                            "en" : [
                              'January',
                              'February',
                              'March',
                              'April',
                              'May',
                              'June',
                              'July',
                              'August',
                              'September',
                              'October',
                              'November',
                              'December'
                            ]
                          }
                        }}
                        onChange={function(maskedValue, year, month) {
                          currentComponent.handleEndDate(maskedValue)
                        }}
                        />
                    </div>
                    {this._isSelected ?
                      <div className="reset" onClick={this.handleResetStartEnd}>{t('earningsPage.resetButtonText')}</div>
                      :
                      ''
                    }
                  </div>
                  <div className="right sort-box">
                    <span className="name">{t('earningsPage.sortField.sortFieldTitle')} </span>
                    <select onChange={this.handleSortBy} value={this.state.sortBy}>
                      <option value="date">{t('earningsPage.sortField.sortOption1')}</option>
                      <option value="views">{t('earningsPage.sortField.sortOption2')}</option>
                      <option value="amount">{t('earningsPage.sortField.sortOption3')}</option>
                    </select>
                  </div>
                </div>
                <div className="earnings-list-box">
                  {Object.keys(this.state.post).length === 0 ?
                    <div className="error-text">{t('earningsPage.noResultErrorText')}</div>
                    :
                    this.state.post.map((list , key) => {
                      const dateToFormat = list.createdAt;

                      return(
                        <div key={key} className="earnings-item clear">
                          <div className="left">
                            <div className="date"><Moment format="DD.MM.YYYY" date={dateToFormat} /></div>
                            <div className="title" title={''}>
                              <ResponsiveEllipsis
                                text={list.title}
                                maxLine='2'
                                ellipsis='...'
                                />
                            </div>
                            <div className="bottom">
                              <PostCountFunc viewCount={list.views} />
                              <div className="price">{Math.round(list.amount) + ' ֏'}</div>
                            </div>
                          </div>
                          <div className="right">
                            <Link className="link" to={'/'+langCode+'/post/'+list.urlKey}>{t('earningsPage.viewPostButtonText')}</Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {this.state.pp < this.state.totalCount ?
                    <Pagination
                      innerClass="pagination clear"
                      hideFirstLastPages
                      pageRangeDisplayed={5}
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.pp}
                      totalItemsCount={this.state.totalCount}
                      onChange={this.handlePageChange}
                      nextPageText={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path className="cls-1" d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"/>
                            </g>
                          </g>
                        </svg>
                      }
                      prevPageText={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path className="cls-1" d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"/>
                            </g>
                          </g>
                        </svg>
                      }
                      />
                    :
                    ''
                  }
                </React.Fragment>
            </div>
        </div>
      
      </React.Fragment>
    );
  }
}

EarningsPostList.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(EarningsPostList);
