export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token , 'lang' : languageCode};
    } else {
        return {};
    }
}
