import React, { Component } from 'react';
// import { authHeader } from '../../_helpers';
// import axios from 'axios';
// import Pagination from "react-js-pagination";

import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
// import PostItem from '../post/post'

//import ImageAsset from './image-asset.jpg';
// import Header from '../headerComponent/header'
// import Footer from '../footerComponent/footer'
// const apiUrl = process.env.REACT_APP_API;
//const imageUrl = process.env.REACT_APP_IMAGE_URL;
//import { HashLink as Link } from 'react-router-hash-link';

class LegalPage extends Component {
  // constructor(props) {
  //   super(props);
  //   //this.scrollToView = this.scrollToView.bind(this);
  //
  // }
  _isMounted = false;

  componentDidMount(){
    //let currentComponent = this;
    // window.onload = function (event) {
    //   currentComponent.scrollToView()
    // }
    // currentComponent.scrollToView()

  }

  // scrollToView() {
  //   if (this.props.location.hash && this.props.location.hash !== undefined) {
  //     // let currentComponent = this;
  //     var hash = this.props.location.hash.replace('#','');
  //     const elementPosition = document.getElementById(hash).offsetTop-40;
  //     window.scroll({
  //       top: elementPosition,
  //       left: 0,
  //       behavior: "smooth"
  //     });
  //   }
  // }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    window.scroll(0, 0);
    // const scrollWithOffset = (el, offset) => {
    //   const elementPosition = el.offsetTop - offset;
    //   window.scroll({
    //     top: elementPosition,
    //     left: 0,
    //     behavior: "smooth"
    //   });
    // }
    const { t } = this.props;
    return (
      <div>
        <div className="legal-page-container">
          <MetaTags/>
          <div className="wrapper">
            <h1 className="title">{t('legal.pageTitle')}</h1>
            <div className="content-box" dangerouslySetInnerHTML={{__html: t('legal.pageContent')}}>
              
            
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

LegalPage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(LegalPage);
