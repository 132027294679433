import Postinfo from './components/userDashbord/post/postInfo'
import Postadd from './components/userDashbord/post/add'


import Earningsinfo from './components/userDashbord/earnings/earningsInfo'
import EarningsHistory from './components/userDashbord/earnings/earningsHistory'
import WithDraw from './components/userDashbord/earnings/withDraw'

import AdvertisementInfo from './components/userDashbord/advertisement/advertisement'
import AdvertisementAdd from './components/userDashbord/advertisement/add'

import UserEdit from './components/userAccount/edit'

import MembershipInfo from './components/userDashbord/membership/view'
import PurchaseInfo from './components/userDashbord/purchase/view'
import OrderStatus from './components/userDashbord/purchase/orderStatus'



let lang = 'en|hy'
const routes = [
  
  { path: `/:lang(${lang})/user-dashbord/post`, exact: true, name: 'Post Info', component: Postinfo },
  { path: `/:lang(${lang})/user-dashbord/post/add`, exact: true, name: 'Post Add', component: Postadd },
  { path: `/:lang(${lang})/user-dashbord/earnings`, exact: true, name: 'Earnings Info', component: Earningsinfo },
  { path: `/:lang(${lang})/user-dashbord/earnings/history`, exact: true, name: 'Earnings History', component: EarningsHistory },
  { path: `/:lang(${lang})/user-dashbord/earnings/withdraw`, exact: true, name: 'With Draw', component: WithDraw },
  { path: `/:lang(${lang})/user-dashbord/advertisement`, exact: true, name: 'Advertisement Info', component: AdvertisementInfo },
  { path: `/:lang(${lang})/user-dashbord/advertisement/add`, exact: true, name: 'Advertisement Add', component: AdvertisementAdd },
  { path: `/:lang(${lang})/user-dashbord/advertisement/add`, exact: true, name: 'Advertisement Add', component: AdvertisementAdd },
  { path: `/:lang(${lang})/user-account/edit`, exact: true, name: 'User edit', component: UserEdit },
  { path: `/:lang(${lang})/user-dashbord/memberships`, exact: true, name: 'Membership Info', component: MembershipInfo },
  { path: `/:lang(${lang})/user-dashbord/purchase/:id`, exact: true, name: 'Purchase Info', component: PurchaseInfo },
  { path: `/:lang(${lang})/user-dashbord/purchase/order-status/:id`, exact: true, name: 'Order Status Info', component: OrderStatus },
];


export default routes;
