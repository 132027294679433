import React, { Component } from 'react';

import axios from 'axios';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)



const apiUrl = process.env.REACT_APP_API;
class Livepage extends Component {
  _isMounted = false;
  _isLoaded = false;

  constructor(props) {
    super(props);
    this.state = {
        live:[],
        completed:[]
    }
  }


  componentDidMount(){
      let currentComponent = this;
      axios.get(apiUrl + '/livestream', {
        headers:{'lang' : this.props.match.params.lang}
      })
          .then(function(response) {
            currentComponent._isMounted = true
            const liveData = response.data.success.videos;
            currentComponent.setState({ live:liveData });
          });

  }
  componentWillUnmount() {
    this._isMounted = false
    this._isLoaded = false
  }



  render() {
    if(this._isMounted === false ) {
      return false
    }
    const liveData = this.state.live
    // console.log(liveData)
    const { t } = this.props;

    return (
      <div>
        <div className="live-container">
          <MetaTags/>
          <div className="main-container">
              <div className="wrapper">
                {liveData.length > 0 ?
                  liveData.map((item , key) => {
                    if(key === 0) {
                      return (
                        <div key={key} className="featured-video">
                          <iframe key={key} title={item.title} src={item.embed}></iframe>
                          <div className="title">
                            <ResponsiveEllipsis
                              text={item.title}
                              maxLine='4'
                              ellipsis='...'
                            />
                          </div>
                        </div>
                      )
                    }
                    return('')
                  })
                :
                  <div className="no-live">{t('livePage.errorTitle')}</div>
                }
                <div className="live-posts-list clear">
                  {
                    liveData.length > 0 ?
                    liveData.map((item , key) => {
                      if(key > 0) {
                        return (
                          <div key={key} className="item ">
                            <div className="iframe-wrap ">
                              <iframe key={key} title={item.title} src={item.embed}></iframe>
                            </div>
                            <div className="title">
                              <ResponsiveEllipsis
                                text={item.title}
                                maxLine='4'
                                ellipsis='...'
                              />
                            </div>
                          </div>
                        )
                      }
                      return('')
                    })
                    :
                      ''
                  }
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}


Livepage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Livepage);