import React, { Component } from "react";
import axios from "axios";
import { authHeader } from "../../../_helpers";
// import {
//   Link
// } from 'react-router-dom';
import Moment from "react-moment";
import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const apiUrl = process.env.REACT_APP_API;

class ActiveMembership extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMembership: {},
      redirect: false,
      redirectUrl: "",
      messageFromServer: ""
    };
  }
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    let currentComponent = this;
    axios
      .get(apiUrl + "/memberships/user", {
        headers: authHeader()
      })
      .then(function(response) {
        const activeMembership = response.data;
        currentComponent._isMounted = true;
        if (currentComponent._isMounted) {
          currentComponent.setState({ activeMembership: activeMembership });
        }
      });
  }
  render() {
    // console.log(this.state.activeMembership)
    if (this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
        </div>
      );
    }
    var data = this.state.activeMembership;
    const { t } = this.props;

    if (data.code === 200 && data.success.memberships.length > 0) {
      var dataMemberships = this.state.activeMembership.success.memberships;
      var expierdDate = this.state.activeMembership.success.info.activeTo;
    } else {
      return (
        <div className="active-membership-list">
          <div className="section-title">
            {t("membershipPage.activeSection.title")}
          </div>
          <div className="error-text">
            {t("membershipPage.activeSection.noResult")}
          </div>
        </div>
      );
    }
    // console.log(dataMemberships)
    let expiersText = "";

    if (data.success.info.avtiveDaysCount === 0) {
      expiersText = t("membershipPage.activeSection.dayText3");
    }
    if (data.success.info.avtiveDaysCount === 1) {
      expiersText = `(${data.success.info.avtiveDaysCount} ${t(
        "membershipPage.activeSection.dayText2"
      )})`;
    }
    if(data.success.info.avtiveDaysCount > 1) {
      expiersText = `(${data.success.info.avtiveDaysCount} ${t(
        "membershipPage.activeSection.dayText"
      )})`;
    }
    return (
      <div className="active-membership-list">
        <div className="section-title">
          {t("membershipPage.activeSection.title")}
        </div>
        <div className="section-description">
          {t("membershipPage.activeSection.description")}
        </div>
        {expierdDate ? (
          <div className="section-info">
            {t("membershipPage.activeSection.expierdText")}{" "}
            <span>
              <Moment format="DD.MM.YYYY" date={expierdDate} /> {expiersText}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="active-list">
          <div className="active-list-header">
            <div className="row clear">
              <div className="item">
                {t("membershipPage.activeSection.activeListInfo.durationText1")}
              </div>
              <div className="item">
                {t("membershipPage.activeSection.activeListInfo.priceText1")}
              </div>
              <div className="item">
                {t("membershipPage.activeSection.activeListInfo.dateText1")}
              </div>
              <div className="item last"></div>
            </div>
          </div>
          {dataMemberships.map((list, key) => {
            const dateToFormat = list.startAt;
            return (
              <div key={key} className="active-list-item">
                <div className="row clear">
                  <div className="item">
                    <span>
                      {t(
                        "membershipPage.activeSection.activeListInfo.durationText2"
                      )}{''}
                    </span>
                    <span>{list.membership.title}</span>
                  </div>
                  <div className="item">
                    <span>
                      {t(
                        "membershipPage.activeSection.activeListInfo.priceText2"
                      )}{" "}
                    </span>
                    <span>{list.membership.cost + " ֏"}</span>
                  </div>
                  <div className="item">
                    <span>
                      {t(
                        "membershipPage.activeSection.activeListInfo.dateText2"
                      )}{" "}
                    </span>
                    <span>
                      <Moment format="DD.MM.YYYY" date={dateToFormat} />
                    </span>
                  </div>
                  <div className="item last">
                    <a
                      rel="noopener noreferrer"
                      href={imageUrl + list.invoice}
                      target="_blank"
                    >
                      {t(
                        "membershipPage.activeSection.activeListInfo.invoceText"
                      )}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ActiveMembership.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(ActiveMembership);
