import React, { Component } from 'react';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'

import {
  Link
} from 'react-router-dom';
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
class AdsAndSponsored extends Component {

  _isMounted = false;

  componentDidMount(){
      // let currentComponent = this;
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    window.scroll(0, 0);
    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    const adsHomeSvg = `<svg class="scheme-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 360">
        <g transform="translate(12466 -5761)">
          <rect class="cls-1" width="560" height="360" rx="6" transform="translate(-12466 5761)"/>
          <g transform="translate(-1)">
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12312 5794)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12312 5876)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12312 6020)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12245 5794)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12245 5876)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12245 6020)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12178 5794)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12178 5876)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12178 6020)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12111 5794)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12111 5876)"/>
            <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12111 6020)"/>
            <g id="Rectangle_829" data-name="Rectangle 829" class="cls-3" transform="translate(-12312 5962)">
              <rect class="cls-4" width="254" height="40" rx="2"/>
              <rect class="cls-5" x="0.5" y="0.5" width="253" height="39" rx="1.5"/>
            </g>
          </g>
        </g>
      </svg>`
      const adsPostInnerSvg = `
        <svg class="scheme-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 360">
          <g id="Group_323" data-name="Group 323" transform="translate(12466 -6400)">
            <g id="Group_317" data-name="Group 317">
              <rect class="cls-1" width="560" height="360" rx="6" transform="translate(-12466 6400)"/>
              <g transform="translate(-4 1)">
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6432)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6584)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6508)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6660)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6470)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6622)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6546)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6698)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6452)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6452)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6603)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6527)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6679)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6489)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6641)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6565)"/>
                <rect class="cls-2" width="180" height="10" rx="2" transform="translate(-12308 6717)"/>
                <g class="cls-3" transform="translate(-12115 6432)">
                  <rect class="cls-4" width="60" height="110" rx="2"/>
                  <rect class="cls-5" x="0.5" y="0.5" width="59" height="109" rx="1.5"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      `
      const sponsoredSectionSvg = `
      <svg class="scheme-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 360">
        <g transform="translate(12466 -6839)">
          <g transform="translate(0 -18)">
            <rect  class="cls-1" width="560" height="360" rx="6" transform="translate(-12466 6857)"/>
            <g class="cls-2" transform="translate(-12313 7003)">
              <rect class="cls-5" width="53" height="68" rx="2"/>
              <rect class="cls-6" x="0.5" y="0.5" width="52" height="67" rx="1.5"/>
            </g>
            <g class="cls-2" transform="translate(-12246 7002)">
              <rect class="cls-5" width="53" height="69" rx="2"/>
              <rect class="cls-6" x="0.5" y="0.5" width="52" height="68" rx="1.5"/>
            </g>
            <g  class="cls-2" transform="translate(-12179 7002)">
              <rect class="cls-5" width="53" height="69" rx="2"/>
              <rect class="cls-6" x="0.5" y="0.5" width="52" height="68" rx="1.5"/>
            </g>
            <g class="cls-2" transform="translate(-12112 7002)">
              <rect class="cls-5" width="53" height="69" rx="2"/>
              <rect class="cls-6" x="0.5" y="0.5" width="52" height="68" rx="1.5"/>
            </g>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12313 6892)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12246 6892)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12179 6892)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12112 6892)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12313 7114)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12246 7114)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12179 7114)"/>
            <rect class="cls-3" width="53" height="68" rx="2" transform="translate(-12112 7114)"/>
            <line class="cls-4" x2="254" transform="translate(-12312.5 6981.5)"/>
            <line class="cls-4" x2="254" transform="translate(-12312.5 7091.5)"/>
          </g>
        </g>
      </svg>
      `
      const sponsoredPostSvg = `
        <svg class="scheme-9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 360">
          <g transform="translate(12466 -7278)">
            <rect class="cls-1" width="560" height="360" rx="6" transform="translate(-12466 7278)"/>
            <g transform="translate(-20 -13)">
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12293 7478)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12293 7314)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12293 7396)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12293 7560)"/>
              <rect class="cls-2" width="53" height="69" rx="2" transform="translate(-12226 7477)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12226 7314)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12226 7396)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12226 7560)"/>
              <rect class="cls-2" width="53" height="69" rx="2" transform="translate(-12159 7477)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12159 7314)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12159 7396)"/>
              <g class="cls-3" transform="translate(-12092 7478)">
                <rect class="cls-4" width="53" height="68" rx="2"/>
                <rect class="cls-5" x="0.5" y="0.5" width="52" height="67" rx="1.5"/>
              </g>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12092 7314)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12092 7396)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12159 7560)"/>
              <rect class="cls-2" width="53" height="68" rx="2" transform="translate(-12092 7560)"/>
            </g>
          </g>
        </svg>
      `
    return (
      <div>
        <div className="ads-sponsored-page-container">
          <MetaTags/>
          <div className="wrap-box">
            <h1 className="section-title">{t('adsAndSponsored.ads.pageTitle')}</h1>
            <div className="text-box">
              <div className="box textAlignLeft" dangerouslySetInnerHTML={{__html: t('adsAndSponsored.ads.pageContent' , {adsHomeSvg , adsPostInnerSvg})}}>
              </div>
              <div className="box">
                <Link className="button no-margin" to={`/${languageCode}/user-dashbord/advertisement/add`}>{t('adsAndSponsored.ads.buttonText')}</Link>
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="wrap-box">
            <h2 className="section-title">{t('adsAndSponsored.sponsored.pageTitle')}</h2>
            <div className="text-box">
              <div className="box textAlignLeft" dangerouslySetInnerHTML={{__html: t('adsAndSponsored.sponsored.pageContent' , {sponsoredSectionSvg , sponsoredPostSvg})}}></div>
              <div className="box">
                <Link className="button" to={`/${languageCode}/user-dashbord/post/add`}>{t('adsAndSponsored.sponsored.buttonText')}</Link>
              </div>
              <span>{t('adsAndSponsored.globalInfo.pageContent')}</span>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

AdsAndSponsored.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(AdsAndSponsored);