import React, { Component } from 'react';
import { history } from '../../_helpers';

import {
  Router,
  Route,
  Switch,Redirect
} from 'react-router-dom';
import routes from '../../routes';

class DefaultLayout extends Component {
  render() {
    window.scroll(0, 0);
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    return (
      <Router history={history}>
        <div >
            <Switch>
                {routes.map((route, idx) => {
                   return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                       <route.component {...props} key={new Date().getTime()} />
                     )} /> )
                     : (null);
                 },
               )}
               <Redirect from="/" to={`/${languageCode}/not-found`} />

            </Switch>
        </div>
      </Router>
    );
  }
}

export default DefaultLayout;
