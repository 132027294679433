import React, { Component } from "react";
import { Link } from "react-router-dom";
//import Moment from "react-moment";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import axios from "axios";
import PostItem from "./post";
//import PostCountFunc from "./postCountFunc";
import ReactPlaceholder from "react-placeholder";
import Responsive from "react-responsive-decorator";

const apiUrl = process.env.REACT_APP_API;

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
const imageUrl = process.env.REACT_APP_IMAGE_URL;

class FeaturedPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredPost: [],
      ready: false,
      mediumPlaceholder: false,
      smallPlaceholder: false
    };
  }
  _isMounted = false;
  componentDidMount() {
    let currentComponent = this;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    var isLoaded = axios
      .get(apiUrl + "/posts/featured-posts", {
        headers: { lang: languageCode }
      })

      .then(function(response) {
        currentComponent._isMounted = true;

        const featuredPosts = response.data;
        currentComponent.setState({ featuredPost: featuredPosts });
      })
      .catch(error => this.setState({ ready: false }));

    isLoaded.then(x => {
      setTimeout(function() {
        currentComponent.setState({ ready: true });
        currentComponent.props.isComponentLoaded(true);
      }, 300);
    });
    this.props.media({ minWidth: 520 }, () => {
      this.setState({
        smallPlaceholder: false
      });
    });
    this.props.media({ maxWidth: 520 }, () => {
      this.setState({
        smallPlaceholder: true
      });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this._isMounted === false) {
      return false;
    } else {
      const pinedPost = this.state.featuredPost;
      //const defaultPostdateToFormat = pinedPost.defaultPost.publishedDate;
      const awesomePlaceholder = this.state.smallPlaceholder ? (
        <div className="my-awesome-placeholder">
          <div className="default">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2479.471"
              height="2331.609"
              viewBox="0 0 2479.471 2331.609"
            >
              <g
                id="Group_385"
                data-name="Group 385"
                transform="translate(-20338.375 -1018.391)"
              >
                <rect
                  id="Rectangle_1073"
                  data-name="Rectangle 1073"
                  width="2479.47"
                  height="1397.238"
                  transform="translate(20338.375 1018.391)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1079"
                  data-name="Rectangle 1079"
                  width="565.301"
                  height="120"
                  transform="translate(20338.375 2548)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1074"
                  data-name="Rectangle 1074"
                  width="947.118"
                  height="120"
                  transform="translate(20338.375 2718)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1075"
                  data-name="Rectangle 1075"
                  width="2080.762"
                  height="120"
                  transform="translate(20338.375 2889)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1078"
                  data-name="Rectangle 1078"
                  width="1647.458"
                  height="120"
                  transform="translate(20338.375 3060)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1077"
                  data-name="Rectangle 1077"
                  width="2261.305"
                  height="120"
                  transform="translate(20338.375 3230)"
                  fill="#cdcdcd"
                />
              </g>
            </svg>
          </div>
        </div>
      ) : (
        <div className="my-awesome-placeholder">
          <div className="default">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1166.252"
              height="394.837"
              viewBox="0 0 1166.252 394.837"
            >
              <g
                id="Group_390"
                data-name="Group 390"
                transform="translate(-378 -1408)"
              >
                <rect
                  id="Rectangle_1047"
                  data-name="Rectangle 1047"
                  width="700.599"
                  height="394.837"
                  transform="translate(378 1408)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1081"
                  data-name="Rectangle 1081"
                  width="97.083"
                  height="17.515"
                  transform="translate(1118.133 1540.613)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1082"
                  data-name="Rectangle 1082"
                  width="140.12"
                  height="17.515"
                  transform="translate(1118.133 1568.637)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1083"
                  data-name="Rectangle 1083"
                  width="378.399"
                  height="17.515"
                  transform="translate(1118.133 1596.661)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1084"
                  data-name="Rectangle 1084"
                  width="426.119"
                  height="17.515"
                  transform="translate(1118.133 1624.685)"
                  fill="#cdcdcd"
                />
                <rect
                  id="Rectangle_1085"
                  data-name="Rectangle 1085"
                  width="311.102"
                  height="17.515"
                  transform="translate(1118.133 1652.709)"
                  fill="#cdcdcd"
                />
              </g>
            </svg>
          </div>
        </div>
      );
      let language = JSON.parse(localStorage.getItem("lang"));
      let languageCode = language.code;
      return (
        <div className="pined-item-container">
          <div className="wrapper">
            <ReactPlaceholder
              ready={this.state.ready}
              customPlaceholder={awesomePlaceholder}
            >
              <div className="default">
                <Link
                  to={
                    "/" + languageCode + "/post/" + pinedPost.defaultPost.urlKey
                  }
                  className="image-box"
                  style={{
                    backgroundImage: `url(${imageUrl +
                      pinedPost.defaultPost.thumbnail})`
                  }}
                ></Link>

                <div className="text-box">
                  <div className="table">
                    <div className="cell">
                      <div className="status">
                        <Link
                          className="permalink"
                          to={
                            "/" +
                            languageCode +
                            "/category/" +
                            pinedPost.defaultPost.category.urlKey
                          }
                        >
                          {pinedPost.defaultPost.category.title}
                        </Link>
                      </div>
                      {/*
                      <div className="box clear">
                        <div className="post-date">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 299.99 299.99"
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <path d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0Zm64.85,178.52H151.25c-.21,0-.41-.05-.63-.06s-.41.06-.63.06a10.37,10.37,0,0,1-10.37-10.37V62.25a10.38,10.38,0,0,1,20.75,0v95.53h54.47a10.37,10.37,0,0,1,0,20.74Z" />
                              </g>
                            </g>
                          </svg>
                          <Moment
                            format="DD.MM.YYYY"
                            date={defaultPostdateToFormat}
                          />
                        </div>
                        <PostCountFunc
                          viewCount={pinedPost.defaultPost.views}
                        />
                      </div>
                      */}
                      {pinedPost.defaultPost.user !== undefined &&
                      pinedPost.defaultPost.user !== null ? (
                        <div className="autor">
                          <Link
                            to={
                              "/" +
                              languageCode +
                              "/user/" +
                              pinedPost.defaultPost.user.id
                            }
                          >
                            {pinedPost.defaultPost.user.firstName +
                              " " +
                              pinedPost.defaultPost.user.lastName}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="title">
                        <Link
                          to={
                            "/" +
                            languageCode +
                            "/post/" +
                            pinedPost.defaultPost.urlKey
                          }
                        >
                          <ResponsiveEllipsis
                            text={pinedPost.defaultPost.title}
                            maxLine="3"
                            ellipsis="..."
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ReactPlaceholder>
            <div className="featured">
              <div className="post-item-container clear">
                <PostItem category={true} dateView={false} posts={pinedPost.featuredPosts} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default Responsive(FeaturedPosts);
