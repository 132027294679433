import React, { Component } from 'react';
import axios from 'axios';

import {Helmet} from "react-helmet";

const apiUrl = process.env.REACT_APP_API
var qs = require('query-string');

class MetaTags extends Component {
  constructor(props) {
      super(props);
      this.state = {
        metaInfo:{},
      }
  }
  _isLoaded = false;
  componentDidMount() {

    let currentComponent = this;
    currentComponent._isLoaded = true;
    let postdata = {
        url: window.location.pathname
      };
      let language = JSON.parse(localStorage.getItem('lang'))
      let languageCode = language.code
    axios.post( apiUrl + '/metas',qs.stringify(postdata),{headers:{'lang' : languageCode}}).then(res => {
      const data = res.data.success.metas;
      // console.log(data)
      if(currentComponent._isLoaded){
        if(data!==undefined || data!== '' || data !== null){
          this.setState({ metaInfo:data});
        }
      }
    })


  }
  componentWillUnmount() {
    this._isLoaded = false
  }

  render() {
    if(this._isLoaded === false) {
      return false;
    }
    return (
      <Helmet>
        <title>{this.state.metaInfo.title}</title>
        <meta name="title" content={this.state.metaInfo.title} />
        <meta name="description" content={this.state.metaInfo.description} />
        <meta name="keywords" content={this.state.metaInfo.keywords}/>
        <meta property="og:type" content="website"></meta>
      </Helmet>
    );
  }
}

export default MetaTags;
