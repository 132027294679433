import React, { Component } from 'react';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'


import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

class AboutPage extends Component {

  _isMounted = false;

  componentDidMount(){
      // let currentComponent = this;


  }



  componentWillUnmount() {
    this._isMounted = false
  }


  render() {
    window.scroll(0, 0);
    const { t } = this.props;

    return (
      <div>
        <div className="about-page-container">
          <MetaTags/>
          <div className="table">
            <div className="cell">
              <h1>{t('about.pageTitle')}</h1>
              <div className="text-box" dangerouslySetInnerHTML={{__html: t('about.pageContent')}}>
                
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

AboutPage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(AboutPage);