import React, { Component } from 'react';
import UserDashbordSidebar from '../sideBar';
import { authHeader } from '../../../_helpers';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'

import SubmitedAdvertisement from './submitedAdvertisement';
import ApprovedAdvertisement from './publishedAdvertisement';
import axios from 'axios';


import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';


const apiUrl = process.env.REACT_APP_API;

class Advertisementinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render:false,
      post:{}
    }
    this.reRender = this.reRender.bind(this);
  }
  _isMounted = false
  reRender() {
    this.setState({ render: true });
  }

  componentDidMount() {
    let currentComponent = this;
    currentComponent._isMounted = true;
    let curentUser = JSON.parse(localStorage.getItem('user'));
    const queryParams = {};

    queryParams.userId = curentUser._id
    axios.get(apiUrl+'/commercial/',{params:queryParams,headers:authHeader()})
    .then(function(response) {
      const posts = response.data;
      if(currentComponent._isMounted) {
        currentComponent.setState({ post:posts});
      }
    });
  }

  render() {
    if(this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    }
    let post = this.state.post
    const { t } = this.props;

    return (
      <div>
        <div className="advertisement-info-container">
          <MetaTags/>
          <UserDashbordSidebar />
          <div className="post-info-box">
            {post.advertisements !== undefined && post.advertisements.total !== 0?
              // false ?
              <div className="wrapper">
                <ApprovedAdvertisement />

                <SubmitedAdvertisement/>
              </div>
              :
              <div className="wrapper">
                <div className="section-title">{t('myAdvertisement.noResultTitle')}</div>
              </div>
            }
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

Advertisementinfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Advertisementinfo);
