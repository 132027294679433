import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import FeaturedPosts from '../post/featuredPosts'
import PostItem from '../post/post'
import AdvertisementBlock from '../userDashbord/advertisement/advertisementBlock'
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'

const apiUrl = process.env.REACT_APP_API;

class Homepage extends Component {
  _isMounted = false;
  _isLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
        post:[],
        ready:false,
        isComponentLoaded:false,
        mediumPlaceholder:false,
        smallPlaceholder:false,
    }
    this.isComponentLoaded = this.isComponentLoaded.bind(this)
  }

  isComponentLoaded(){
    this.setState({
      isComponentLoaded:true
    })
    this._isLoaded = true
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let isComponentLoaded = nextProps.isComponentLoaded
    if(isComponentLoaded === true) {
      this.setState({
        isComponentLoaded
      })
    }
  }
  componentDidMount(){
    let currentComponent = this;
    //const headers = {'Authorization':authHeader().Authorization,'lang' : this.props.match.params.lang};
    axios.get(apiUrl+'/homepage-categories',{headers:{'lang' : this.props.match.params.lang}})
    .then(function(response) {
      currentComponent._isMounted = true
      const posts = response.data;
      if(currentComponent._isMounted) {
        currentComponent.setState({ post:posts });
      }
    }).catch(error => this.setState({ready: false }));

  }
  componentWillUnmount() {
    this._isMounted = false
    this._isLoaded = false
  }

  render() {
    if(this._isLoaded === false && this._isMounted === false) {
      /*return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )*/
      return false;
    }
    // console.log(this.state.isComponentLoaded)
    const list = this.state.post
    // console.log(this.props.match.params.lng)
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    return (
      
        <div className="home-container">
          <MetaTags/>
          
          <div className="main-container">
              <FeaturedPosts isComponentLoaded={this.isComponentLoaded}/>
              {this.state.isComponentLoaded ?
                <div className="post-section">
                  <div className="wrapper">
                    {list.map((list , key) => {
                      if(list.posts.length > 0 ) {
                        return(
                          <div key={key} className="post-item-wrap">
                            {key===2 ?
                              <AdvertisementBlock key={'home-page'} type='home-page'/>
                            :
                              ''
                            }
                            <div  key={key} className="post-item-container clear">
                              {list.sponsored !== undefined && list.sponsored === true ?
                                <div className="section-title">
                                  {list.title}
                                </div>
                                :
                                <Link to={`/${languageCode}/category/${list.urlKey}`} className="section-title">
                                  {list.title}
                                </Link>
                              }
                              <div className="item-wrapper clear">
                                <PostItem key={key} posts={list.posts} />
                              </div>
                            </div>
                          </div>
                        )
                      }
                      return '';
                    })}
                  </div>
                </div>
                :
                  ''
              }
          </div>
          {this.state.isComponentLoaded ?
            <Footer />
          :
            ''
          }
        </div>

    );
  }
}
export default Homepage