import React, { Component } from 'react';
import {
  Col,
  FormGroup,
  Label,
} from 'reactstrap';

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
class UploadImage  extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorClasses:props.errorClasses ,
      errors:  props.errors,
      selectedBlock:  props.selectedBlock,
      visible:  props.visible,
      className:  props.className,
      imageFile:  props.image,
      uploadErrorMsg: '',
      uploadedImage:'',
      fileName:''
    }
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  UNSAFE_componentWillReceiveProps(props){
    this.setState(props);
  }
  handleselectedFile(e){
  const { t } = this.props;

    const selectedBlock = this.state.selectedBlock;
    var file = e.target.files[0];
    var _URL = window.URL || window.webkitURL;
    var img = new Image();
    var currentComponent  = this;
    img.onload = function() {
      //console.log(this.width,this.height,selectedBlock,file);
        if(this.width !== selectedBlock.imageWidth && this.height !== selectedBlock.imageHeight ){
            currentComponent.setState({uploadErrorMsg:t('newAdvertisement.uploadImageErrorText'),fileName:''});
            document.getElementById('adv-image').value = "";
            return false;
        }else{
          currentComponent.props.handleUploadImage(file);
          currentComponent.setState({uploadErrorMsg:'',fileName:file.name});
        }
      }
      img.src = _URL.createObjectURL(file);

  }
  handleClick () {
    document.getElementById('adv-image').click()
  }
  render(){
  const { t } = this.props;

    return (
      <FormGroup row className={this.state.className+' custom-attach-file'}>
          <Col md="3" >
            <Label htmlFor="adv-image">{t('newAdvertisement.uploadeButtonText')}</Label>
            <span className="file-name">{this.state.fileName}</span>
          </Col>
          <Col xs="12" md="9">
            <input accept=".jpg,.gif,.png" type="file" id="adv-image"className={this.state.errorClasses.image+' form-control'}  name="image"  onChange={this.handleselectedFile} />
            {
              /* this.state.imageFile ? <img  src={process.env.REACT_APP_IMAGE_URL+this.state.imageFile} alt="" /> : ''*/
            }
            <div className="invalid-feedback">{this.state.errors.image}</div>
            <div className="invalid-error">{this.state.uploadErrorMsg}</div>
          </Col>
      </FormGroup>
    );
  }
}

UploadImage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(UploadImage);