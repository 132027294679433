import React, { Component } from "react";
import axios from "axios";
import { authHeader } from "../../../_helpers";
// import {
//   Redirect
// } from 'react-router-dom';
import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

const apiUrl = process.env.REACT_APP_API;

class AvailableMembership extends Component {
  constructor(props) {
    super(props);
    //const type=props.type;

    this.state = {
      availableMembership: {},
      redirect: false,
      redirectUrl: "",
      messageFromServer: ""
    };

    this.handelRequest = this.handelRequest.bind(this);
  }
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    let currentComponent = this;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    axios
      .get(apiUrl + "/memberships", {
        headers: {
          lang: languageCode
        }
      })
      .then(function(response) {
        const availableMembership = response.data;
        currentComponent._isMounted = true;
        if (currentComponent._isMounted) {
          currentComponent.setState({
            availableMembership: availableMembership
          });
        }
      });
  }
  handelRequest(e) {
    var itemId = e.target.id;
    // let currentComponent = this
    const itemData = {
      object: itemId,
      type: "Membership"
    };
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    axios
      .post(apiUrl + "/payment/proccess", itemData, {
        headers: authHeader()
      })
      .then(function(response) {
        var data = response.data;
        if (data.code === 200) {
          let url = "/" + languageCode + "/user-dashbord/purchase/" + data.token;
          window.location = url;
          //currentComponent.setState({redirectUrl:url,redirect:true});
        }
      });
  }
  render() {
    if (this._isMounted === false) {
      return false;
    }
    var data = this.state.availableMembership;
    if (data.code === 200) {
      var dataMemberships = this.state.availableMembership.success.memberships;
    } else {
      return false;
    }
    const { t } = this.props;

    return (
      <div className="available-membership-list">
        <div className="section-title">
          {t("membershipPage.availableSection.title")}
        </div>
        <div
          className="section-description"
          dangerouslySetInnerHTML={{
            __html: t("membershipPage.availableSection.description")
          }}
        ></div>

        <div className="available-list clear">
          {dataMemberships.map((list, key) => {
            return (
              <div key={key} className="available-item">
                <div className="title">{list.title}</div>
                <div className="price">{list.cost + " ֏"}</div>
                <div
                  id={list.id}
                  className="button"
                  onClick={this.handelRequest}
                >
                  {t("membershipPage.availableSection.payButtonText")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

AvailableMembership.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(AvailableMembership);
