import React, { Component } from 'react';
import UserDashbordSidebar from '../sideBar';
import ActiveMembership from './activeMembership';
import AvailableMembership from './availableMembership';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

class MembershipInfo extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="membership-info-container">
          <MetaTags/>
          <UserDashbordSidebar />
          <div className="membership-info-box">
            <div className="wrapper">
              <div className="global-info">
                <h1 className="section-title">{t('membershipPage.pageTitle')}</h1>
                <div className="section-description">{t('membershipPage.pageDescription')}</div>
              </div>
              <ActiveMembership />
              <AvailableMembership />
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}


MembershipInfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(MembershipInfo);
