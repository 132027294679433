import React, { Component } from 'react';
import axios from 'axios'
import { authHeader } from '../../../_helpers';
import {
 Redirect,Link
} from 'react-router-dom';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
const apiUrl = process.env.REACT_APP_API
//var querystring = require('querystring');
class OrderStatus extends Component {
  constructor(props) {
    super(props);
    //const type=props.type;
    this.state = {
      orderInfo:{},
      redirect:false,
      redirectUrl:'',
      messageFromServer:'',
    }


  }
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidMount(){
    let currentComponent = this;
    currentComponent._isMounted = true

    axios.get(apiUrl+'/payments/'+currentComponent.props.match.params.id,{
      headers:authHeader()
    }).then(function(response) {
      if(response.data.code === 200 ){
        if(currentComponent._isMounted) {
          currentComponent.setState({
            orderInfo:response.data.success
          })
        }
      }else if(response.data.code === 404) {
        let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
        currentComponent.setState({
            redirect:true,
            redirectUrl:'/'+langCode+'/not-found'
        });
      }
    });
  }


  render() {
    if(this.state.redirect){
       return (<Redirect to={this.state.redirectUrl} />);
     }
     if(this._isMounted) {
       var data = this.state.orderInfo.payment;
       //console.log(data)
     }else {
       return false
     }
    const { t } = this.props;
    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <div>
        <div className="orderstatus-info-container">
          <MetaTags/>
          {data !== '' && data !== undefined ?
            data.status.code === 'paid' ?
            <div className="info">
              <div className="section-title">{t('purchasePage.payItemTitle')}</div>
              <div className="box">
                <div className="title">{t('purchasePage.successDescription')}</div>
                <Link to={`/${langCode}/`} className="button">{t('purchasePage.backToHomeButtonText')}</Link>
              </div>
            </div>
            :
            <div className="info">
              <div className="section-title">{t('purchasePage.errorTitle')}</div>
              <div className="box">
                <div className="title">{t('purchasePage.errorDescription')}</div>
                <Link to={`/${langCode}/`} className="button">{t('purchasePage.backToHomeButtonText')}</Link>
              </div>
            </div>
            :
            ''
          }
        </div>
        <Footer/>
      </div>
    );
  }
}

OrderStatus.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(OrderStatus);
