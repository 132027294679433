import React, { Component } from 'react';
import ReserveDays from './ReservedDays';
import validator from 'validator';
import FormValidator from '../../validations';
import axios from 'axios'
import { authHeader } from '../../../_helpers';
import UploadImage from './UploadImage';
import { connect } from 'react-redux';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'
import Select from 'react-select';

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
import {
  Link
} from 'react-router-dom';
const BlocksList = ({translate,data,selectedBlockId,onClick}) => {
  let returnedItems = '';

  if(data){
    // console.log(data)

    returnedItems = Object.keys(data).map(id  => {

      let item = data[id];
      const selectedClass = item._id === selectedBlockId ? 'active item':'item';
      return (
        <div className={ selectedClass } key={id}  onClick={() => onClick(item._id)}>
          {item.type === 'post-inner' ?
            <svg className="ads-baner-inner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 270">
              <g id="Group_327" data-name="Group 327" transform="translate(-101 -968)">
                <rect id="Rectangle_977" data-name="Rectangle 977" className="cls-1" width="270" height="270" rx="6" transform="translate(101 968)"/>
                <g id="Group_325" data-name="Group 325" transform="translate(-1 -1)">
                  <rect id="Rectangle_979" data-name="Rectangle 979" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1004)"/>
                  <rect id="Rectangle_986" data-name="Rectangle 986" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1109)"/>
                  <rect id="Rectangle_990" data-name="Rectangle 990" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1169)"/>
                  <rect id="Rectangle_980" data-name="Rectangle 980" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1034)"/>
                  <rect id="Rectangle_988" data-name="Rectangle 988" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1139)"/>
                  <rect id="Rectangle_984" data-name="Rectangle 984" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1079)"/>
                  <rect id="Rectangle_992" data-name="Rectangle 992" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1199)"/>
                  <rect id="Rectangle_981" data-name="Rectangle 981" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1019)"/>
                  <rect id="Rectangle_987" data-name="Rectangle 987" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1124)"/>
                  <rect id="Rectangle_983" data-name="Rectangle 983" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1064)"/>
                  <rect id="Rectangle_991" data-name="Rectangle 991" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1184)"/>
                  <rect id="Rectangle_982" data-name="Rectangle 982" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1049)"/>
                  <rect id="Rectangle_989" data-name="Rectangle 989" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1154)"/>
                  <rect id="Rectangle_985" data-name="Rectangle 985" className="cls-2" width="132" height="7" rx="2" transform="translate(145 1094)"/>
                  <g id="Rectangle_978" data-name="Rectangle 978" className="cls-3" transform="translate(289 1003)">
                    <rect className="cls-4" width="40" height="80" rx="2"/>
                    <rect className="cls-1" x="0.5" y="0.5" width="39" height="79" rx="1.5"/>
                  </g>
                </g>
              </g>
            </svg>
            :
            <svg className="ads-baner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 270">
              <g id="Group_328" data-name="Group 328" transform="translate(-101 -1370)">
                <rect id="Rectangle_993" data-name="Rectangle 993" className="cls-1" width="270" height="270" rx="6" transform="translate(101 1370)"/>
                <g id="Group_326" data-name="Group 326" transform="translate(-1 1)">
                  <rect id="Rectangle_1001" data-name="Rectangle 1001" className="cls-2" width="38" height="48" rx="2" transform="translate(145 1461)"/>
                  <rect id="Rectangle_1003" data-name="Rectangle 1003" className="cls-2" width="38" height="48" rx="2" transform="translate(145 1402)"/>
                  <rect id="Rectangle_1002" data-name="Rectangle 1002" className="cls-2" width="38" height="48" rx="2" transform="translate(145 1559)"/>
                  <rect id="Rectangle_999" data-name="Rectangle 999" className="cls-2" width="38" height="48" rx="2" transform="translate(194 1461)"/>
                  <rect id="Rectangle_1004" data-name="Rectangle 1004" className="cls-2" width="38" height="48" rx="2" transform="translate(194 1402)"/>
                  <rect id="Rectangle_1000" data-name="Rectangle 1000" className="cls-2" width="38" height="48" rx="2" transform="translate(194 1559)"/>
                  <rect id="Rectangle_997" data-name="Rectangle 997" className="cls-2" width="38" height="48" rx="2" transform="translate(242 1461)"/>
                  <rect id="Rectangle_1005" data-name="Rectangle 1005" className="cls-2" width="38" height="48" rx="2" transform="translate(242 1402)"/>
                  <rect id="Rectangle_998" data-name="Rectangle 998" className="cls-2" width="38" height="48" rx="2" transform="translate(291 1461)"/>
                  <rect id="Rectangle_1006" data-name="Rectangle 1006" className="cls-2" width="38" height="48" rx="2" transform="translate(291 1402)"/>
                  <rect id="Rectangle_996" data-name="Rectangle 996" className="cls-2" width="38" height="48" rx="2" transform="translate(242 1559)"/>
                  <rect id="Rectangle_994" data-name="Rectangle 994" className="cls-2" width="38" height="48" rx="2" transform="translate(291 1559)"/>
                  <g id="Rectangle_995" data-name="Rectangle 995" className="cls-3" transform="translate(145 1521)">
                    <rect className="cls-4" width="184" height="26" rx="2"/>
                    <rect className="cls-1" x="0.5" y="0.5" width="183" height="25" rx="1.5"/>
                  </g>
                </g>
              </g>
            </svg>
          }
          <div className="title">{item.name}</div>
          <div className="price">{item.perDayCost+ ' ֏ ' + translate('newAdvertisement.advertisementItemCoastLableText')}</div>
          <div className="size">{item.imageWidth+'x'+item.imageHeight}</div>
        </div>
      )
    })
  }

  return returnedItems;

}
const apiUrl = process.env.REACT_APP_API
class Advertisement extends Component {
  constructor(props) {
    super(props);
    this.state = {
        advertisement:{
          _id:'',
          blockId: '',
          description: '',
          reserveDays: '',
          image: '',
          status: 1,
          category:'',
          language:''
        },
        priceForDay:'',
        selectedBlock:{},
        advertisementsblocks:{},
        messageFromServer: '',
        messageColor:'success',
        visible: false,
        errors: [],
        errorClasses: [],
        isHidden:'hidden',
        blockedDays:[],
        reservedDays:[],
        reservedDaysFromServer:[],
        reservedDaysCount:0,
        randomKey:'12323433434',
        showReservedDays:false,
        startDate:'',
        endDate:'',
        addPrice:'',
        selectedOption:'',
        options:{},
        categories:[],

    }
    this._isMounted = false;
    this._isSelected = false;
    this._isBlockChanged = false;
    this._isCategorySelect = false;
    this.reservedDaysChange = this.reservedDaysChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.changeBlock = this.changeBlock.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateadvertisement = this.updateadvertisement.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.categoryOptions = this.categoryOptions.bind(this);
    this.logChange = this.logChange.bind(this);
    this.languageChange = this.languageChange.bind(this);
  }
  _isMounted = false;
  categoryOptions() {
    var countryList = this.state.categories;
    var option = {}
    var sayHello = countryList.map(function (data) {
       option = {
        value:data._id,
        label:data.title
      }
      return option;
    });

    return sayHello
  }
  logChange(val) {
    this._isMounted = false
    var selectedOptionName = val.label
    var selectedOptionValue = val.value
    const {advertisement} = this.state;
    advertisement.category = selectedOptionValue
    this.setState({
      advertisement,
      selectedOption:selectedOptionName
    })
    this._isBlockChanged = true;
    this._isMounted = true
    // console.log(selectedOptionName,selectedOptionValue)

  }

  componentDidMount(){
    let currentComponent=this;
    currentComponent._isMounted = true;
     let req =  axios.get(apiUrl+'/commercial/blocks',{headers:authHeader()})
          .then(function(response) {
              const advertisementsblocks = response.data;
              if(currentComponent._isMounted) {
                currentComponent.setState({ advertisementsblocks });
              }
          });
    req.then(x => axios.get( apiUrl + '/categories', {
      headers:{'lang' : this.props.match.params.lang}
    }).then(res => {
      const categories = res.data.success.categories;
      //console.log(categories)
      if(currentComponent._isMounted) {
        this.setState({ categories});
      }
    }))
  }
  changeBlock(id){
    this._isMounted = false
    const {advertisement,advertisementsblocks} = this.state;
    advertisement.block =id;
    advertisement.language ='';

    this._isBlockChanged = false;
    let currentComponent = this;
    var isHidden = '';
    var foundIndex = advertisementsblocks.findIndex(x => x.id === id);
    var selectedBlock = advertisementsblocks[foundIndex];
    var blockedDays = selectedBlock.reserveDays;
    this._isSelected = false;

    const randomKey = Date.now();

    currentComponent._isLanguageSelect = true;
    if(selectedBlock.isCategory !== true) {
      currentComponent._isLanguageSelect = true;

      currentComponent._isCategorySelect = false;

    }else {
      currentComponent._isCategorySelect = false;

    }
    this._isMounted = true
    // if(selectedBlock.isCategory === true) {
    //     currentComponent._isCategorySelect = true;
    //     currentComponent._isBlockChanged = false;
    //     this._isMounted = true

    // }else {
    //   setTimeout(function(){
    //     currentComponent._isCategorySelect = false;
    //     currentComponent._isBlockChanged = true;

    //     currentComponent.setState({
    //       selectedOption:''
    //     });
    //   },10)
    // }
    this.setState({
      advertisement,
      isHidden,
      selectedBlock,
      blockedDays,
      randomKey:randomKey,
      reservedDays:[],
      startDate:'',
      endDate:'',
      reservedDaysCount:'',
      selectedOption:'',
      addPrice:''
    },() => {
        this._isMounted = true
    });


  }

  handleUploadImage(data){
      this.fileData = data;
      var advertisement = this.state.advertisement;
      advertisement.image = data;
      this.setState({advertisement});
  }
  handleValidation(){
  const { t } = this.props;

        let {advertisement,reservedDays} = this.state;
        let errors = {};
        let messageFromServer='';
        let messageColor='';
        let errorClasses = {};
        let formIsValid = true,visible=false;
        const validationRules = [
          {
            field: 'description',
            method: validator.isEmpty,
            validWhen: false,
            message: t('newAdvertisement.descriptionField.errorMessage')
          },
        ];
        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(advertisement);
        if(!validation.isValid){
          formIsValid = false;
          for (var variable in validation) {
            if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
              if(validation[variable]['isInvalid']){
                errors[variable] = validation[variable]['message'];
                errorClasses[variable] = "is-invalid";
              }
            }
          }
        }
        if(reservedDays.length === 0){
              messageFromServer = t('newAdvertisement.reservedDaysErrorMessage');
              messageColor = 'danger';
              formIsValid = false;
              visible = true;
        }
        if(advertisement.block === undefined || advertisement.block === ''){
              messageFromServer = t('newAdvertisement.reservedDaysErrorMessage');
              messageColor = 'danger';
              formIsValid = false;
              visible = true;
        }
        this.setState({errors: errors,errorClasses:errorClasses,messageFromServer:messageFromServer,messageColor:messageColor,visible:visible});
        return formIsValid;
   }
   updateadvertisement(e) {
    let currentComponent = this;
    if(this.handleValidation()){
      let requestUrl = '/commercial/insert';
      const {advertisement,reservedDays} = this.state;
      const user = JSON.parse(localStorage.getItem('user'));
      const sendingData =  new FormData();
      sendingData.append('block',advertisement.block);
      sendingData.append('createdByAdmin',0);
      sendingData.append('userId',user._id);
      sendingData.append('title',advertisement.title);
      sendingData.append('description',advertisement.description);
      sendingData.append('reserveDays',JSON.stringify(reservedDays));
      sendingData.append('statusCode',advertisement.statusCode);
      sendingData.append('language',advertisement.language);
      if(advertisement.category !== undefined && advertisement.category !== '') {
        sendingData.append('category',advertisement.category);
      }
      sendingData.append('isAdmin','0');
      sendingData.append('image', advertisement.image);
      const headers = {'Authorization':authHeader().Authorization,'lang' : this.props.match.params.lang,'Content-Type': 'multipart/form-data','isadmin':1};
      axios.post(process.env.REACT_APP_API+requestUrl,sendingData,
         {
            headers: headers

          }).then(function(response) {
            if(response.data.message && response.data.message !== ''){
              currentComponent.setState({
                  messageFromServer: response.data.message,
                  messageColor:'success'

                });
            }
              if(response.data.code!==200 ){
                currentComponent.setState({messageColor:'danger'});
                if(response.data.error && response.data.error.message !== ''){
                  currentComponent.setState({
                      messageFromServer: response.data.message
                  });
                }
              }else{
               let advertisement = response.data.advertisement;
               let language = JSON.parse(localStorage.getItem('lang'))
               let languageCode = language.code
                let url = '/'+languageCode+'/user-dashbord/advertisement';
                currentComponent.setState({advertisement});
                window.location = url
              }
              currentComponent.setState({visible:true});

      });
    }

  }
  componentWillUnmount() {
     this._isMounted = false;
     this._isSelected = false;
     this._isBlockChanged = false;
     this._isCliked = false

  }
   handleChange(e){
    const name =  e.target.name;
    const advertisement = this.state.advertisement;
    advertisement[name] = e.target.value;
    this.setState({
        advertisement
    });
  }

  reservedDaysChange(reservedDays , reservedDaysCount){
    if(reservedDays !== undefined && reservedDaysCount > 0){
      var price = this.state.selectedBlock.perDayCost * reservedDays.length
      //console.log(price)
      this._isSelected = true;
      this.setState({
        reservedDaysCount,
        reservedDays,
        startDate:reservedDays[0],
        endDate:reservedDays[reservedDays.length-1],
        addPrice:price
      });
    }
  }

  languageChange(val) {
    var selectedOptionValue = val.value
    let currentComponent = this
    const {advertisement , selectedBlock} = this.state;
    advertisement.language = selectedOptionValue
    advertisement.category = ''
    const randomKey = Date.now();
    if(selectedBlock.isCategory === true) {
        currentComponent._isCategorySelect = true;
        currentComponent._isBlockChanged = false;

    }else {
      setTimeout(function(){
        currentComponent._isCategorySelect = false;
        currentComponent._isBlockChanged = true;

        currentComponent.setState({
          selectedOption:''
        });
      },10)
    }


    this.setState({
      advertisement,
      selectedBlock,
      randomKey,
      reservedDays:[],
      startDate:'',
      endDate:'',
      reservedDaysCount:'',
    })
    
    // console.log(selectedOptionName,selectedOptionValue)

  }
  render() {
    if(this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      );
    }
    let categoryOptions = this.categoryOptions()
  const { t } = this.props;
  let language = JSON.parse(localStorage.getItem('lang'))
  let languageCode = language.code

  const languageOptions = [
    { value: 'hy', label: 'Հայերեն' },
    { value: 'en', label: 'English' },
  ]
  // console.log(this.state)
  // console.log(this.state.reservedDays[[this.state.advertisement.language]])
  // console.log(this.state.blockedDays[[this.state.advertisement.language]])
  // console.log(this.state.blockedDays)

  
  var blockedDaysList
  

    if(this.state.advertisement.category !== '' && this.state.advertisement.category  !== undefined && this._isCategorySelect) {
      const blockedList = this.state.blockedDays[[this.state.advertisement.language]];
      if(blockedList === undefined ){
        blockedDaysList = []
      }else {

        if(blockedList[this.state.advertisement.category] === undefined || blockedList[this.state.advertisement.category] === '') {
          blockedDaysList = []
        }else {
          blockedDaysList = blockedList[this.state.advertisement.category]
        }
      }
    }else {
      const blockedList = this.state.blockedDays[[this.state.advertisement.language]]
      if(blockedList === undefined || blockedList === ''){
        blockedDaysList = []
      }else {
        blockedDaysList = blockedList
      }
    }
    return (
      <div className="advertisement-add-info-container">
        <MetaTags/>
        <div className="wrapper">
            <h1 className="section-title">{t('newAdvertisement.pageTitle')}</h1>
            <div className="section-description">{t('newAdvertisement.pageDescription')}</div>
            <div className="advertisement-info-box clear">
              <BlocksList translate={t} selectedBlockId={this.state.advertisement.block} data={this.state.advertisementsblocks}  onClick={this.changeBlock}/>
            </div>
            { this._isLanguageSelect ? 
              <div className="languge-box">
                {/*<div className="section-title">{t('newAdvertisement.languageSectionTitle')}</div>*/}
                <div className="section-description">{t('newAdvertisement.languageSectionDescription')}</div>
                <div className="row category-row">
                  <Select
                      classNamePrefix="react-select"
                      isMulti={false}
                      isSearchable ={false}
                      value={[languageOptions.find(opt =>opt.value === this.state.advertisement.language)]}
                      name="language"
                      options={languageOptions}
                      onChange={this.languageChange}
                      className={' react-select'}
                      placeholder = {t('newAdvertisement.languageField.placeholder')}
                    />

                </div>
              </div>
            :
              ''
            }
            {this._isCategorySelect ?
              <div className="category-box">
                <div className="section-description">{t('newAdvertisement.categorySectionDescription')}</div>

                  <div className="row category-row">
                    <Select
                        classNamePrefix="react-select"
                        isMulti={false}
                        isSearchable ={false}
                        value={[categoryOptions.find(opt =>opt.value === this.state.advertisement.category)]}
                        name="postCategory"
                        options={categoryOptions}
                        onChange={this.logChange}
                        className={this.state.errorClasses.postCategory + ' react-select'}
                        placeholder ={t('newAdvertisement.categoryField.placeholder')}
                      />

                    <div className="invalid-feedback">{this.state.errors.postCategory}</div>
                  </div>
              </div>
              :
              ''
            }
            {this._isBlockChanged ?
              <div>
                <div className="date-picker">
                  {/*<div className="section-title">{t('newAdvertisement.datePickerTitle')}</div>*/}
                  <div className="section-description">{t('newAdvertisement.datePickerDescription')}</div>
                  <div className="wrap-box">
                    <ReserveDays key={this.state.randomKey} randomKey={this.state.randomKey} blockedDays={blockedDaysList}  reservedDays={this.state.reservedDays}
                      reservedDaysCount={this.state.reservedDaysCount} reservedDaysChange={this.reservedDaysChange} />
            <div className="invalid-feedback">{this.state.messageFromServer}</div>
                  </div>
                  <div className="text-info">
                    {this._isSelected ?
                      <div>
                        <div className="date-info">{t('newAdvertisement.pickedDaysTitle')} <span>{this.state.startDate}</span> / <span>{this.state.endDate}</span></div>
                        <div className="price">{t('newAdvertisement.pickedDaysPrice')} <span>{this.state.addPrice+' ֏'}</span></div>
                      </div>
                      :
                      ''
                    }
                  </div>
                </div>
                <div className="form">
                  <div className="row">
                    {/*<div className="section-title">{t('newAdvertisement.advertisementInfoTitle')}</div>*/}
                    <div className="section-description">{t('newAdvertisement.advertisementInfoDescription')}</div>
                    <textarea name="description" onChange={this.handleChange} className={this.state.errorClasses.description} placeholder={t('newAdvertisement.descriptionField.placeholder')}></textarea>
                    <div className="invalid-feedback">{this.state.errors.description}</div>
                  </div>
                  <div className="row">
                    <UploadImage errors={this.state.errors} errorClasses={this.state.errorClasses} visible={this.state.visible} className={this.state.isHidden} selectedBlock={this.state.selectedBlock} imageFile={this.state.advertisement.image} handleUploadImage={this.handleUploadImage}/>
                  </div>
                  <div className="row bottom">
                    <Link className="cancel" to={`/${languageCode}/user-dashbord/advertisement`}>{t('newAdvertisement.cancelButtonText')}</Link>
                    <button onClick={this.updateadvertisement} className="submit">{t('newAdvertisement.submitButtonText')}</button>
                  </div>
                </div>
              </div>
              :
              ''
            }
          </div>
        <Footer/>
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}
const AdvertisementAdd = connect(mapStateToProps)(Advertisement);

AdvertisementAdd.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(AdvertisementAdd);

