import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { authHeader } from '../../../_helpers';
import Pagination from "react-js-pagination";
import PostCountFunc from '../../post/postCountFunc'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

import {
  Link
} from 'react-router-dom';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
require('react-month-picker-input/dist/react-month-picker-input.css');
// require('react-month-picker-input/src/i18n.ts');
const apiUrl = process.env.REACT_APP_API

class EarningsMonthList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        post:[],
        activePage: 1,
        totalCount:'',
        pp:12,
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
  }
  _isMounted = false;
  _isSelected = false;
  _isShow = false;
  componentWillUnmount() {
    this._isMounted = false
    this._isSelected = false
  }
  componentDidMount(){
    let currentComponent = this;
    currentComponent._isMounted = true;
    currentComponent.handleSubmitedPosts();
    let req = axios.get(apiUrl+'/earnings/',{headers:authHeader()})
    .then(function(response) {
        const total = response.data.success.total;
        if(currentComponent._isMounted){
          currentComponent.setState({ totalCount:total });
        }
    });

  }

  handleSubmitedPosts(pageNumber,startDate,endDate,sortBy) {
    let currentComponent = this;
    currentComponent._isMounted = true
    const queryParams = {};
    let PostPerPage = currentComponent.state.pp
    queryParams.limit = PostPerPage;

    if(pageNumber !== undefined && pageNumber !== '') {
      queryParams.offset = pageNumber;
    }
    if(startDate !== undefined && startDate !== '') {
      queryParams.startDate = startDate;
    }
    if(endDate !== undefined && endDate !== '') {
      queryParams.endDate = endDate;
    }
    axios.get(apiUrl+'/earnings/',{params:queryParams,headers:authHeader()})
        .then(function(response) {
            const posts = response.data.success.earnings;
            if(currentComponent._isMounted){
              currentComponent.setState({ post:posts,activePage: pageNumber });
            }
        });
  }
  handlePageChange(pageNumber) {
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    this.handleSubmitedPosts(pageNumber,startDate,endDate);
  }

  render() {
    if(this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    }
    
    const { t } = this.props;

    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <React.Fragment>
        <div className="month-list">
            <div className="section-title">
            {t('earningsPage.pageTitle2')}
            </div>
            <div className="section-description">{t('earningsPage.pageDescription2')}</div>
            <div className="earnings-info-box-container">
            
                <React.Fragment >
                
                    <div className="earnings-list-box">
                        {Object.keys(this.state.post).length === 0 ?
                                <div className="error-text">{t('earningsPage.noResultErrorText')}</div>
                            :
                                <div className="active-list">
                                    <div className="active-list-header">
                                        <div className="row clear">
                                            <div className="item">
                                                {t("earningsPage.month.earningText")}
                                            </div>
                                            <div className="item">
                                                {t("earningsPage.month.viewsText")}
                                            </div>
                                            <div className="item">
                                                {t("earningsPage.month.monthText")}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.post.map((list, key) => {
                                        const dateToFormat = list.date;
                                        return (
                                        <div key={key} className="active-list-item">
                                            <div className="row clear">
                                            <div className="item">
                                                <span>
                                                {t("earningsPage.month.earningText2")}{" "}
                                                </span>
                                                <span>{Math.round(list.total) + " ֏"}</span>
                                            </div>
                                            <div className="item">
                                                <span>
                                                {t("earningsPage.month.viewsText2")}{" "}

                                                </span>
                                                <span>{list.views}</span>
                                            </div>
                                            <div className="item">
                                                <span>
                                                {t("earningsPage.month.monthText2")}{" "}

                                                </span>
                                                <span>
                                                <Moment format="MM.YYYY" date={dateToFormat} />
                                                </span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        );
                                    })}
                                </div>
                            
                        }
                    </div>
                    {this.state.pp < this.state.totalCount ?
                        <Pagination
                        innerClass="pagination clear"
                        hideFirstLastPages
                        pageRangeDisplayed={5}
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pp}
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        nextPageText={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                <path className="cls-1" d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"/>
                                </g>
                            </g>
                            </svg>
                        }
                        prevPageText={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                <path className="cls-1" d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"/>
                                </g>
                            </g>
                            </svg>
                        }
                        />
                        :
                        ''
                    }
                </React.Fragment>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

EarningsMonthList.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(EarningsMonthList);
