import React, { Component } from "react";
import MetaTags from "../metaTags";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import Footer from "../footerComponent/footer";

import PostItem from "../post/post";

import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

const apiUrl = process.env.REACT_APP_API;

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
      activePage: 1,
      totalCount: "",
      pp: 12,
      user: [],
      redirect: false,
      redirectUrl: ""
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
  }
  _isMounted = false;
  _isLoaded = false;
  componentDidMount() {
    let currentComponent = this;
    currentComponent.handleSubmitedPosts();
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    //let curentUser = JSON.parse(localStorage.getItem('user'));
    axios
      .get(
        apiUrl +
          "/posts/user-accepted-posts/" +
          currentComponent.props.match.params.id,
        {
          headers: { lang: languageCode }
        }
      )
      .then(function(response) {
        const total = response.data.total;
        currentComponent.setState({ totalCount: total });
      });

    axios
      .get(apiUrl + "/users/" + currentComponent.props.match.params.id, {
        headers: { lang: languageCode }
      })
      .then(function(response) {
        const user = response.data;
        currentComponent._isLoaded = true;
        if (user.id === undefined) {
          currentComponent.setState({
            redirect: true,
            redirectUrl: `/${languageCode}/not-found`
          });
        } else {
          currentComponent.setState({ user: user });
        }
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmitedPosts(pageNumber) {
    let currentComponent = this;
    const queryParams = {};
    let PostPerPage = currentComponent.state.pp;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    if (pageNumber !== undefined && pageNumber !== "") {
      queryParams.p = pageNumber;
    }
    queryParams.pp = PostPerPage;
    axios
      .get(
        apiUrl +
          "/posts/user-accepted-posts/" +
          currentComponent.props.match.params.id,
        { params: queryParams, headers: { lang: languageCode } }
      )
      .then(function(response) {
        const posts = response.data.posts;
        currentComponent._isMounted = true;
        currentComponent.setState({ post: posts, activePage: pageNumber });
      });
  }
  handlePageChange(pageNumber) {
    window.scroll(0, 0);
    this.handleSubmitedPosts(pageNumber);
  }
  render() {
    if (this._isMounted === false || this._isLoaded === false) {
      return "";
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    const { t } = this.props;

    let curentUser = this.state.user;
    return (
      <div>
        <div className="user-page-container">
          <MetaTags />
          <div className="wrapper">
            <h1 className="section-title">
              {t("userPage.userNameText", {
                name: curentUser.firstName + " " + curentUser.lastName
              })}
            </h1>
            <div className="post-item-container clear">
              <PostItem
                category={true}
                showUser={false}
                posts={this.state.post}
              />
            </div>
            {this.state.pp < this.state.totalCount ? (
              <Pagination
                innerClass="pagination clear"
                hideFirstLastPages
                pageRangeDisplayed={5}
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pp}
                totalItemsCount={this.state.totalCount}
                onChange={this.handlePageChange}
                nextPageText={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 243.01 414.68"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="cls-1"
                          d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                        />
                      </g>
                    </g>
                  </svg>
                }
                prevPageText={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 243.01 414.68"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="cls-1"
                          d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

UserPage.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(UserPage);
