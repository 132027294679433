import React, { Component } from 'react';
import GooglePicker from 'react-google-picker';
import GoogleIcon from '../../../Assets/images/svg/Gdrive-icon.svg';


class Googlepicker extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      selectedItem: [],
    }
  }

  onSuccess(files) {


    this.props.onSuccess(files);
  }
  onCancel(e) {

  }
  render() {
    return (
      <GooglePicker clientId={'375040050301-kg3oclpbdpkpgppgaekdn0ttauvv9p5f.apps.googleusercontent.com'}
                    developerKey={'AIzaSyA02bdAt2L4HlV-VyE-DCnwBYq6VBxSdMc'}
                    scope={['https://www.googleapis.com/auth/drive.readonly']}
                    onChange={data => this.onSuccess(data)}
                    onAuthFailed={data => console.log('on auth failed:', data)}
                    multiselect={true}
                    navHidden={true}
                    authImmediate={false}
                    viewId={'DOCS'}>
                    <div className="google-button">
                        <img alt='' src={GoogleIcon} /><span>Google Drive</span>
                    </div>
      </GooglePicker>
    )
  }
}
export default Googlepicker;
