import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import { authHeader } from '../../../_helpers';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

const apiUrl = process.env.REACT_APP_API
class EarningsHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts:{},
      userBalance:{},
      activePage: 1,
      totalCount:'',
      pp:12,
    }
    this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

  }
  _isMounted = false;
  _isMounted2 = false;
  _isLoaded = false;


  componentWillUnmount(){
    this._isMounted = false;
    this._isLoaded = false;
    this._isMounted2 = false;
  }
  componentDidMount() {
    let currentComponent = this;

    currentComponent.handleSubmitedPosts();
   let req = axios.get(apiUrl+'/payouts/user',{headers:authHeader()})
        .then(function(response) {
          currentComponent._isMounted2 = true;
            const total = response.data.success.total;
            if(currentComponent._isMounted2){
              currentComponent.setState({ totalCount:total });
            }
        });
    req.then(x => axios.get(apiUrl+'/userbalance',{headers:authHeader()})
        .then(function(response) {
          currentComponent._isMounted = true;

            const userBalance = response.data.success.balance;
            // console.log(userBalance)
            if(currentComponent._isMounted){
              currentComponent.setState({ userBalance });
            }
        }));
  }
  handleSubmitedPosts(pageNumber) {
    let currentComponent = this;
    const queryParams = {};
    let PostPerPage = currentComponent.state.pp
    queryParams.limit = PostPerPage;

    if(pageNumber !== undefined && pageNumber !== '') {
      queryParams.offset = pageNumber;
    }

    axios.get(apiUrl+'/payouts/user',{params:queryParams,headers:authHeader()})
        .then(function(response) {
            const posts = response.data.success.payouts;
            currentComponent._isLoaded = true
            if(currentComponent._isLoaded){
              currentComponent.setState({ posts,activePage: pageNumber });
            }
        });
  }
  handlePageChange(pageNumber) {
    window.scroll(0, 0);
    this.handleSubmitedPosts(pageNumber);
  }
  render() {
    if(this._isMounted === false || this._isLoaded === false || this._isMounted2 === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    } else {
      var userBalance = this.state.userBalance
      var earningsHistoryList = this.state.posts
      // console.log(earningsHistoryList)
    }
    const { t } = this.props;
    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <div>
        <div className="earnings-history-info-container">
          <MetaTags/>
          <div className="wrapper">
            <h1 className="section-title">{t('earningsPage.historyPageTitle')}</h1>
            <div className="info-box">
              <div className="item">
                <div className="title">{t('earningsPage.viewCountText')}</div>
                <div className="value">{userBalance.views}</div>
              </div>
              <div className="item">
                <div className="title">{t('earningsPage.totlaBalanceText')}</div>
                <div className="value">{parseInt((userBalance.total * 100) / 100 , 0) + ' ֏'}</div>
              </div>
              <div className="item">
                <div className="title">{t('earningsPage.availableBalanceText')}</div>
                <div className="value">{parseInt((userBalance.balance * 100) / 100 , 0) + ' ֏'}</div>
              </div>
            </div>
            <div className="button-row">
              <Link to={`/${langCode}/earning-calculations`}>{t('earningsPage.earningCalculationButtonText')}</Link>
            </div>
            <div className="history-list-box">
              {
                earningsHistoryList.map((list , key) =>{
                  const dateToFormat = list.createdAt;
                  return(
                    <div key={key} className="history-item clear">
                      <div className="left">
                        <div className="date"><Moment format="DD.MM.YYYY" date={dateToFormat} /></div>
                        <div className="price" title={''}>{list.amount+ ' ֏'}</div>
                      </div>
                      <div className="right">
                        <div className="status" data-statuscode={list.statusData.code} data-status={list.statusData.text.toLowerCase()}>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {this.state.pp < this.state.totalCount ?
                <Pagination
                  innerClass="pagination clear"
                  hideFirstLastPages
                  pageRangeDisplayed={5}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pp}
                  totalItemsCount={this.state.totalCount}
                  onChange={this.handlePageChange}
                  nextPageText={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path className="cls-1" d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"/>
                        </g>
                      </g>
                    </svg>
                  }
                  prevPageText={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path className="cls-1" d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"/>
                        </g>
                      </g>
                    </svg>
                  }
                  />
                :
                ''
              }
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}


EarningsHistory.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(EarningsHistory);
