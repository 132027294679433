import React, { Component } from 'react';
import axios from 'axios';
// import {
//   Link
// } from 'react-router-dom';
import ReactGA from "react-ga";

const apiUrl = process.env.REACT_APP_API
const imageUrl = process.env.REACT_APP_IMAGE_URL

class AdvertisementBlock extends Component {
  constructor(props) {
    super(props);
    const type = props.type
    const category = props.category
    this.state = {
      type,
      category,
      data:{},
    }
  }
  _isMounted = false;
  componentDidMount() {
    let currentComponent = this;
    const queryParams = {};
    let type = currentComponent.state.type
    let category = currentComponent.state.category

    if(type !== undefined && type !== '') {
      queryParams.type = type;
    }
    if(category !== undefined && category !== '') {
      queryParams.category = category;
    }
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    axios.get(apiUrl+'/commercial/block',{params:queryParams,headers:{'lang' : languageCode}})
        .then(function(response) {
            const data = response.data.success;
            currentComponent._isMounted = true;

            if(currentComponent._isMounted){
              currentComponent.setState({ data });
            }
        });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let type = nextProps.type
    let category = nextProps.category
    this.setState({
      type,
      category
    })
  }
  componentWillUnmount(){
    this._isMounted = false
  }
  captureOutboundLink(e) {
    let url = 'google.com'

    console.log(url)
   
  }

  render(){
   if(this._isMounted === false) {
     return false
   }else {
     var adsBlock = this.state.data
     // console.log(adsBlock)
     if(adsBlock.advertisement !== undefined && adsBlock.advertisement !== '' && Object.keys(adsBlock.advertisement).length !== 0){
       adsBlock = adsBlock.advertisement
     }else {
       if(adsBlock.default !== undefined){
         adsBlock = adsBlock.default
       }else {
         return false;
       }
     }
   }

    return (
      adsBlock.url !== undefined && adsBlock.url !== '' ?
        <div className="clear-div">
          <div className="commercial-block">
          <ReactGA.OutboundLink
              eventLabel={adsBlock.url}
              to={adsBlock.url}
              target="_blank"
              trackerNames={['tracker2']}
            >
              <img src={imageUrl+adsBlock.image} alt=''/>
            </ReactGA.OutboundLink>
            
          </div>
        </div>
      :
        adsBlock !== undefined  ?
        <div className="clear-div">
          <div className="commercial-block">
            <img src={imageUrl+adsBlock.image} alt=''/>
          </div>
        </div>
        :
          ''
    )
  }
}
export default AdvertisementBlock;
