import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import PostCountFunc from "./postCountFunc";
// import AdvertisementBlock from '../userDashbord/advertisement/advertisementBlock'

import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

import ReactPlaceholder from "react-placeholder";
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
const imageUrl = process.env.REACT_APP_IMAGE_URL;
class PostItem extends Component {
  constructor(props) {
    super(props);
    const showViews = props.showViewCount
    const posts = props.posts;
    const category = props.category;
    const postCount = props.count;
    const adsBlock = props.adsBlock;
    const categoryId = props.categoryId;
    const showUser = props.showUser;
    const dateView = props.dateView;
    this.state = {
      showViewCount:showViews,
      data: posts,
      category: category,
      postCount,
      adsBlock,
      categoryId,
      ready: false,
      showUser,
      showDateView: dateView === undefined ? true : dateView
    };
  }
  _isMounted = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // let currentComponent = this;
    let data = nextProps.posts;
    let postCount = nextProps.count;
    let adsBlock = nextProps.adsBlock;
    let categoryId = nextProps.categoryId;
    this.setState({
      data,
      postCount,
      adsBlock,
      categoryId
    });
  }
  componentDidMount() {
    this._isMounted = true;
    this.hidePlaceholders(true);
  }
  hidePlaceholders(data) {
    let currentComponent = this;
    if (data === true) {
      setTimeout(function() {
        currentComponent.setState({ ready: true });
      }, 300);
    } else {
      currentComponent.setState({ ready: false });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  placeholderGrid() {
    var awesomePlaceholder = this.state.data.map((list, key) => {
      if (
        this.state.adsBlock &&
        (this.state.categoryId !== undefined || this.state.categoryId !== "") &&
        key === 4
      ) {
        return (
          <div key={key} className="clear-div">
            <div className="ads-block placeholder">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1170"
                height="158"
                viewBox="0 0 1170 158"
              >
                <g
                  id="Group_389"
                  data-name="Group 389"
                  transform="translate(-16812.5 7085)"
                >
                  <line
                    id="Line_66"
                    data-name="Line 66"
                    x2="1170"
                    transform="translate(16812.5 -7084.5)"
                    fill="none"
                    stroke="#cdcdcd"
                    strokeWidth="1"
                  />
                  <line
                    id="Line_67"
                    data-name="Line 67"
                    x2="1170"
                    transform="translate(16812.5 -6927.5)"
                    fill="none"
                    stroke="#cdcdcd"
                    strokeWidth="1"
                  />
                  <rect
                    id="Rectangle_1091"
                    data-name="Rectangle 1091"
                    width="720"
                    height="108"
                    transform="translate(17038 -7060)"
                    fill="#cdcdcd"
                  />
                </g>
              </svg>
            </div>
          </div>
        );
      }
      return (
        <div key={key} className="post-item placeholder">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2479.471"
            height="2331.609"
            viewBox="0 0 2479.471 2331.609"
          >
            <g
              id="Group_385"
              data-name="Group 385"
              transform="translate(-20338.375 -1018.391)"
            >
              <rect
                id="Rectangle_1073"
                data-name="Rectangle 1073"
                width="2479.47"
                height="1397.238"
                transform="translate(20338.375 1018.391)"
                fill="#cdcdcd"
              />
              <rect
                id="Rectangle_1079"
                data-name="Rectangle 1079"
                width="565.301"
                height="120"
                transform="translate(20338.375 2548)"
                fill="#cdcdcd"
              />
              <rect
                id="Rectangle_1074"
                data-name="Rectangle 1074"
                width="947.118"
                height="120"
                transform="translate(20338.375 2718)"
                fill="#cdcdcd"
              />
              <rect
                id="Rectangle_1075"
                data-name="Rectangle 1075"
                width="2080.762"
                height="120"
                transform="translate(20338.375 2889)"
                fill="#cdcdcd"
              />
              <rect
                id="Rectangle_1078"
                data-name="Rectangle 1078"
                width="1647.458"
                height="120"
                transform="translate(20338.375 3060)"
                fill="#cdcdcd"
              />
              <rect
                id="Rectangle_1077"
                data-name="Rectangle 1077"
                width="2261.305"
                height="120"
                transform="translate(20338.375 3230)"
                fill="#cdcdcd"
              />
            </g>
          </svg>
        </div>
      );
    });
    return awesomePlaceholder;
  }

  render() {
    // if(this._isMounted === false) {
    //   return false;
    // }
    // console.log(this.state.data)

    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    return (
      <ReactPlaceholder
        ready={this.state.ready}
        customPlaceholder={this.placeholderGrid()}
      >
        {this.state.data.slice(0, this.state.postCount).map((list, key) => {
          let dateToFormat = "";
          if (list.publishedDate !== null || list.publishedDate !== undefined) {
            dateToFormat = list.publishedDate;
          } else {
            dateToFormat = list.createdAt;
          }
          return (
            <div key={key} className="post-item">
              <Link to={"/" + languageCode + "/post/" + list.urlKey}>
                <div
                  className="image-box"
                  style={{
                    backgroundImage: `url(${imageUrl + list.thumbnail})`
                  }}
                >
                  {list.isSponsored ? (
                    <div className="sponsored-label">
                      {t("myPostPage.publishedSection.sponsoredLableText")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
              {this.state.category !== undefined &&
              this.state.category === true ? (
                <Link
                  className="permalink"
                  to={"/" + languageCode + "/category/" + list.category.urlKey}
                >
                  {list.category.title}{" "}
                </Link>
              ) : (
                ""
              )}
              {this.state.showDateView ? (
                <div className="box clear">
                  <div className="post-date">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 299.99 299.99"
                    >
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0Zm64.85,178.52H151.25c-.21,0-.41-.05-.63-.06s-.41.06-.63.06a10.37,10.37,0,0,1-10.37-10.37V62.25a10.38,10.38,0,0,1,20.75,0v95.53h54.47a10.37,10.37,0,0,1,0,20.74Z" />
                        </g>
                      </g>
                    </svg>
                    <Moment format="DD.MM.YYYY" date={dateToFormat} />
                  </div>
                  {list.isSponsored ? (
                    ""
                  ) :
                  
                    this.state.showViewCount ? (<PostCountFunc viewCount={list.views} />) : null
                  }
                </div>
              ) : (
                ""
              )}

              {this.state.showUser === false ? (
                ""
              ) : list.user !== undefined && list.user !== null ? (
                <div className="autor">
                  <Link
                    to={"/" + languageCode + "/user/" + list.user.id}
                    title={list.user.firstName + " " + list.user.lastName}
                  >
                    <ResponsiveEllipsis
                      text={list.user.firstName + " " + list.user.lastName}
                      maxLine="1"
                      ellipsis="..."
                    />
                  </Link>
                </div>
              ) : (
                ""
              )}

              <Link
                to={"/" + languageCode + "/post/" + list.urlKey}
                title={list.title}
              >
                <div className="title">
                  <ResponsiveEllipsis
                    text={list.title}
                    maxLine="4"
                    ellipsis="..."
                  />
                </div>
              </Link>
            </div>
          );
        })}
      </ReactPlaceholder>
    );
  }
}

PostItem.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(PostItem);
