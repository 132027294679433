import React, { Component } from 'react';
import UserDashbordSidebar from '../sideBar';
import axios from 'axios';
import { authHeader } from '../../../_helpers';
import SubmitedPost from './submitedPost';
import PublishedPost from './publishedPost';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'


import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';


const apiUrl = process.env.REACT_APP_API;

class Postinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post:{}
    }
  }
  _isMounted = false

  componentDidMount(){
    let currentComponent = this;

    axios.get(apiUrl+'/submittedposts/',{headers:authHeader()})
      .then(function(response) {
          currentComponent._isMounted = true

        const posts = response.data.posts;
          currentComponent.setState({ 
            post:posts
          });
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    if(this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    }
    let post = this.state.post
    // console.log(post)
    var className ='';

    if(post.length > 1) {
      className = 'post-info-box no-padding-bottom'
    }else {
      className="post-info-box"
    }

    const { t } = this.props;

    return (
      <div>
        <div className="post-info-container">
        <MetaTags/>
        <UserDashbordSidebar />
        <div className={className}>
          {post !== undefined && post.length !== 0?
            <div className="wrapper">
              <PublishedPost/>

              <SubmitedPost/>
            </div>
            :
            <div className="wrapper">
              <div className="section-title">{t('myPostPage.noResultTitle')}</div>
            </div>
          }
        </div>
      </div>
        <Footer/>
      </div>
    );
  }
}


Postinfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Postinfo);
