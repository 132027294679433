import React, { Component } from "react";
import validator from "validator";
import FormValidator from "../../validations";
import axios from "axios";
import { authHeader } from "../../../_helpers";
import MetaTags from "../../metaTags";

import Footer from "../../footerComponent/footer";
import underscor from "underscore";
import Dropbox from "./Dropbox";
import Googlepicker from "./Googlepicker";
import CustomLink from "./CustomLink";
import AttachIcon from "../../../Assets/images/svg/attach-icon.svg";
import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";

import { Link } from "react-router-dom";
import Select from "react-select";

import ReactModal from "react-modal";

const apiUrl = process.env.REACT_APP_API;
// var querystring = require("querystring");

class Postadd extends Component {
  constructor(props) {
    super(props);
    const type = props.type;

    this.state = {
      post: {
        postTitle: "",
        postContent: "",
        postCategory: "",
        customLink: "",
        isSponsored: false,
        postOtherCategory: ""
      },
      selectedOption: "",
      options: {},
      type: type,
      messageFromServer: "",
      messageColor: "success",
      visible: false,
      collapse: true,
      errors: [],
      errorClasses: [],
      selectedItem: [],
      attacedItem: [],
      showField: false,
      categories: [],
      showModal: false,
      sponsoredCost: "",
      OtherCategoryField: false,
      checked: false,
      atttachedFileSize: 0,
      fileAtttacError: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.bytesToSize = this.bytesToSize.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.selectFieldValue = this.selectFieldValue.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.submitPost = this.submitPost.bind(this);
    this.categoryOptions = this.categoryOptions.bind(this);
    this.logChange = this.logChange.bind(this);
    this.onPickerSuccess = this.onPickerSuccess.bind(this);
    this.handleAttachFile = this.handleAttachFile.bind(this);
    this.handleAttachFileClick = this.handleAttachFileClick.bind(this);
  }
  _isSelected = false;
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false;
    this._isSelected = false;
  }
  handleOpenModal(e) {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleValidation() {
    // let fields = this.state.post;
    const { t } = this.props;

    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    let validationRules = [
      {
        field: "postTitle",
        method: validator.isEmpty,
        validWhen: false,
        message: t("newPost.formInfo.postTitle.errorMessage")
      },
      {
        field: "postContent",
        method: validator.isEmpty,
        validWhen: false,
        message: t("newPost.formInfo.postContent.errorMessage")
      },
      {
        field: "postCategory",
        method: validator.isEmpty,
        validWhen: false,
        message: t("newPost.formInfo.postCategory.errorMessage")
      }
    ];
    if (this.state.OtherCategoryField) {
      validationRules.push({
        field: "postOtherCategory",
        method: validator.isEmpty,
        validWhen: false,
        message: t("newPost.formInfo.postOtherCategory.errorMessage")
      });
    }

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.post);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleValidationField() {
    // let fields = this.state.post;
    const { t } = this.props;

    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "customLink",
        method: validator.isEmpty,
        validWhen: false,
        message: t("newPost.formInfo.customLink.errorMessage")
      },
      {
        field: "customLink",
        method: validator.isURL,
        validWhen: true,
        message: t("newPost.formInfo.customLink.errorMessageLink")
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.post);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleChange(e) {
    const name = e.target.name;
    const post = this.state.post;

    post[name] = e.target.value;
    this.setState({
      post
    });
  }
  handelChecked(e) {
    const post = this.state.post;
    const name = e.target.name;
    if (e.target.checked) {
      post[name] = true;
      this.setState({
        post
        //checked:!this.state.checked
      });
    } else {
      post[name] = false;
      this.setState({
        post
        //checked:!this.state.checked
      });
    }
  }
  handleFieldChange(e) {
    const name = e.target.name;
    const post = this.state.post;
    post[name] = e.target.value;
    this.setState({
      post
    });
  }

  onSuccess(files) {
    this._isSelected = true;
    let selectedItem = this.state.selectedItem;
    let selectedItems = selectedItem.concat(files);
    this.setState({
      selectedItem: selectedItems
    });
  }

  removeItem(e) {
    var selectedItems = this.state.selectedItem;
    var attacedItem = this.state.attacedItem;
    var id = e.target.id;
    let foundIndex;
    var size = e.target.getAttribute("data-size");
    let atttachedFileSize = this.state.atttachedFileSize;
    if (size !== null) {
      size = size / (1024 * 1024);
      atttachedFileSize = atttachedFileSize - size;
      foundIndex = attacedItem.findIndex(x => x.id === id);
      attacedItem.splice(foundIndex, 1);
      this.setState({
        attacedItem: attacedItem,
        atttachedFileSize
      });
    } else {
      foundIndex = selectedItems.findIndex(x => x.id === id);
      selectedItems.splice(foundIndex, 1);
      this.setState({
        selectedItem: selectedItems
      });
    }
  }

  onPickerSuccess(files) {
    if (files.action === "picked") {
      this._isSelected = true;
      let selectedItem = this.state.selectedItem;
      let selectedItems = selectedItem.concat(files.docs);
      this.setState({
        selectedItem: selectedItems
      });
      // console.log(selectedItems)
    }
  }
  selectFieldValue() {
    if (this.handleValidationField()) {
      let selectedItem = this.state.selectedItem;

      var date = new Date();
      var id = "id:" + date.getTime();

      let name = document.getElementById("other-link").value;
      this._isSelected = true;
      let fieldArr = {
        id: id,
        name: name,
        link: name
      };

      selectedItem.push(fieldArr);

      this.setState({
        selectedItem: selectedItem,
        showField: false,
        showModal: false
      });
    }
  }
  handleAttachFile(e) {
    let attacedItem = this.state.attacedItem;
    let date = new Date();
    let files = e.target.files;
    let atttachedFileSize = this.state.atttachedFileSize;
    let fileSize = 0;
    // console.log(e.target.files);
    let allFiles = [];
    let fieldArr = [];
    for (let i = 0; i < files.length; i++) {
      let id = "id:" + date.getTime();
      fieldArr = {
        id: id,
        name: files[i].name,
        bytes: files[i].size,
        file: files[i]
        // link: name
      };

      fileSize += files[i].size;
      allFiles.push(fieldArr);
    }
    let globalSize = fileSize / (1024 * 1024);

    // console.log(fieldArr);
    // console.log(globalSize);
    // console.log(allFiles);
    // console.log(e.target.files);
    if (atttachedFileSize + globalSize <= 10) {
      attacedItem.push(allFiles);
      var asd = underscor.flatten(attacedItem);
      // allFiles.concat(attacedItem);

      this.setState({
        fileAtttacError: "",
        attacedItem: asd,
        atttachedFileSize: atttachedFileSize + globalSize
      });
      this._isSelected = true;
      e.target.value = "";
    } else {
      this.setState({
        fileAtttacError: "is-invalid"
      });
      e.target.value = "";
    }
  }
  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  componentDidMount() {
    let currentComponent = this;
    currentComponent._isMounted = true;
    axios
      .get(apiUrl + "/settings/sponsoredcost", { headers: authHeader() })
      .then(res => {
        const sponsoredCost = res.data.success.setting.cost;
        if (currentComponent._isMounted) {
          this.setState({ sponsoredCost });
        }
      });
    const queryParams = {};
    queryParams.submittedpost = 1;
    axios
      .get(apiUrl + "/categories", {
        params: queryParams,
        headers: {
          lang: this.props.match.params.lang
        }
      })
      .then(res => {
        const categories = res.data.success.categories;
        var post = this.state.post;
        // post.postCategory = categories[0]._id
        if (currentComponent._isMounted) {
          this.setState({ categories, post });
        }
      });
  }

  submitPost() {
    if (this.handleValidation()) {
      let requestUrl = "/submittedposts/insert";
      let sendingData = new FormData();
      sendingData.append(
        "postData",
        JSON.stringify({
          title: this.state.post.postTitle,
          description: this.state.post.postContent,
          category: this.state.post.postCategory,
          isSponsored: this.state.post.isSponsored,
          otherCategory: this.state.post.postOtherCategory
        })
      );
      sendingData.append("files", JSON.stringify(this.state.selectedItem));

      this.state.attacedItem.map((item, key) => {
        sendingData.append(`file[${key}]`, item.file);
        return "";
      });
      const headers = {
        Authorization: authHeader().Authorization,
        lang: this.props.match.params.lang,
        "Content-Type": "multipart/form-data"
      };

      axios
        .post(apiUrl + requestUrl, sendingData, {
          headers: headers
        })
        .then(function(response) {
          let data = response.data;
          // console.log(data)
          if (data.code === 200) {
            let langCode = JSON.parse(localStorage.getItem("lang"));
            langCode = langCode.code;
            window.location = "/" + langCode + "/user-dashbord/post";
          }
        });
    }
  }

  categoryOptions() {
    var countryList = this.state.categories;
    var option = {};
    var sayHello = countryList.map(function(data, key) {
      option = {
        value: data._id,
        label: data.title
      };
      if (key + 1 === countryList.length) {
        option = {
          value: "other",
          label: "Այլ"
        };
      }
      return option;
    });

    return sayHello;
  }
  logChange(val) {
    var selectedOptionValue = val.value;
    const post = this.state.post;

    var category = "postCategory";
    post[category] = selectedOptionValue;
    this.setState({
      post,
      selectedOption: selectedOptionValue
    });

    if (selectedOptionValue === "other") {
      this.setState({
        OtherCategoryField: true
      });
    } else {
      this.setState({
        OtherCategoryField: false
      });
    }
    // this.handleValidation();
  }

  handleAttachFileClick(e) {
    let _this = e.currentTarget
    let id = e.currentTarget.getAttribute('data-id');

    let input = document.getElementById(id);

    input.click()

  }
  render() {
    // console.log(this.state.selectedItem);
    // console.log(this.state.attacedItem);
    // console.log(this.state.atttachedFileSize);

    // console.log(this.categoryOptions())
    if (this._isMounted === false) {
      return (
        <div className="loader-box">
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
        </div>
      );
    }
    let categoryOptions = this.categoryOptions();
    const { t } = this.props;
    let langCode = JSON.parse(localStorage.getItem("lang"));
    langCode = langCode.code;
    return (
      <div className="post-add-container">
        <MetaTags />
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="ReactModalPortal custom-link-popup"
        >
          <div className="other-link-row">
            <div className="box">
              <div className="title">{t("newPost.popUpTitle")}</div>
              <div className="row">
                <input
                  id="other-link"
                  name="customLink"
                  onChange={this.handleFieldChange}
                  className={this.state.errorClasses.customLink}
                  type="text"
                  placeholder={t("newPost.formInfo.customLink.placeholder")}
                />
                <div className="invalid-feedback">
                  {this.state.errors.customLink}
                </div>
              </div>
              <button onClick={this.selectFieldValue} className="button">
                {t("newPost.submitButtonText")}
              </button>
              <button onClick={this.handleCloseModal} className="button">
                {t("newPost.cancelButtonText")}
              </button>
            </div>
          </div>
        </ReactModal>
        <div className="table">
          <div className="cell">
            <div className="wrapper">
              <div className="section-title">{t("newPost.pageTitle")}</div>
              <div className="section-description">
                {t("newPost.pageDescription")}
              </div>
              <div className="form">
                <div className="row">
                  <input
                    type="text"
                    placeholder={t("newPost.formInfo.postTitle.placeholder")}
                    name="postTitle"
                    className={this.state.errorClasses.postTitle}
                    onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.postTitle}
                  </div>
                </div>
                <div className="row country-row">
                  <Select
                    classNamePrefix="react-select"
                    isMulti={false}
                    isSearchable={false}
                    value={[
                      categoryOptions.find(
                        opt => opt.value === this.state.post.postCategory
                      )
                    ]}
                    name="postCategory"
                    options={categoryOptions}
                    onChange={this.logChange}
                    className={
                      this.state.errorClasses.postCategory + " react-select"
                    }
                    placeholder={t("newPost.formInfo.postCategory.placeholder")}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.postCategory}
                  </div>
                </div>
                {this.state.OtherCategoryField ? (
                  <div className="row">
                    <input
                      type="text"
                      placeholder={t(
                        "newPost.formInfo.postOtherCategory.placeholder"
                      )}
                      name="postOtherCategory"
                      className={this.state.errorClasses.postOtherCategory}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.postOtherCategory}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <textarea
                    type="text"
                    placeholder={t("newPost.formInfo.postContent.placeholder")}
                    name="postContent"
                    className={this.state.errorClasses.postContent}
                    onChange={this.handleChange}
                  ></textarea>
                  <div className="invalid-feedback">
                    {this.state.errors.postContent}
                  </div>
                </div>
                <div className="row">
                  <input
                    onChange={this.handelChecked}
                    name="isSponsored"
                    id="paid-post"
                    type="checkbox"
                    className="css-checkbox"
                  />
                  <label className="css-label">
                    {t("newPost.sponserdButtonText")} {this.state.sponsoredCost}{" "}
                    ֏
                  </label>
                </div>
                <div className="text">{t("newPost.sposserdDescription")}</div>
                {this._isSelected ? (
                  <div className="file-list">
                    {this.state.selectedItem.map((item, index) => {
                      return (
                        <div key={index} className="item">
                          <span className="name">{item.name}</span>
                          {item.bytes !== undefined ? (
                            <span className="size">
                              {this.bytesToSize(item.bytes)}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.file !== undefined ? (
                            <span
                              className="remove"
                              data-size={item.bytes}
                              onClick={this.removeItem}
                              id={item.id}
                            >
                              {t("newPost.removeButtonText")}
                            </span>
                          ) : (
                            <span
                              className="remove"
                              onClick={this.removeItem}
                              id={item.id}
                            >
                              {t("newPost.removeButtonText")}
                            </span>
                          )}
                        </div>
                      );
                    })}
                    {this.state.attacedItem.map((item, index) => {
                      return (
                        <div key={index} className="item">
                          <span className="name">{item.name}</span>
                          {item.bytes !== undefined ? (
                            <span className="size">
                              {this.bytesToSize(item.bytes)}
                            </span>
                          ) : (
                            ""
                          )}
                          <span
                            className="remove"
                            data-size={item.bytes}
                            onClick={this.removeItem}
                            id={item.id}
                          >
                            {t("newPost.removeButtonText")}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
                <div className="file-attache">
                  <div className="attach-button">
                    <div>
                      <form className="uploader" encType="multipart/form-data">
                        <input
                          type="file"
                          multiple
                          onChange={this.handleAttachFile}
                          id="file-attache"
                          accept="image/*,video/*,audio/*, .pdf, .doc, .docx, .xls, .xlsx,application/zip, application/rar, application/pdf,application/vnd.ms-excel,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                        <label htmlFor='file-attache' data-id="file-attache">
                            <img alt="" src={AttachIcon} />

                            {t("newPost.attachButtonText")}
                          </label>
                      </form>
                    </div>
                    <p className={`${this.state.fileAtttacError} `}>
                      {t("newPost.attachButtonErrorText")}
                    </p>
                  </div>
                  

                  <Googlepicker onSuccess={this.onPickerSuccess} />
                  <Dropbox onSuccess={this.onSuccess} />
                  <span onClick={this.handleOpenModal}>
                    <CustomLink />
                  </span>
                </div>

                <div className="row bottom">
                  <Link
                    className="cancel"
                    to={`/${langCode}/user-dashbord/post`}
                  >
                    {t("newPost.cancelButtonText")}
                  </Link>
                  <button onClick={this.submitPost} className="submit">
                    {t("newPost.submitButtonText")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Postadd.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(Postadd);
