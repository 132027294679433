import React, { Component } from 'react';
import CloudIcon from '../../../Assets/images/svg/cloud-icon.svg'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';


class CustomLink extends Component {
  // constructor(props) {
  //   super(props);
  //
  // }



  render() {
    const { t } = this.props;

    return (
      <div className="other-cloud">
        <div  className="cloud-button">
            <img alt='' src={CloudIcon} /><span> {t('newPost.customLinkButtonText')}</span>
        </div>
      </div>

    )
  }
}

CustomLink.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(CustomLink);
