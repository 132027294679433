import React, { Component } from 'react';
import {
  Link, NavLink,
} from 'react-router-dom';
import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';



class UserDashbordSidebar extends Component {


  render() {
    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    return (
      <div className="side-bar-continer">
        <div className="side-bar-box">
          <div className="">
            <ul>
              <li>
                <NavLink to={`/${languageCode}/user-dashbord/post`} activeClassName="active" aria-hidden="true">{t('header.userToolTipOption2')}</NavLink>
                <Link className="add-button" to={`/${languageCode}/user-dashbord/post/add`}>
                  <svg aria-hidden="true" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <NavLink to={`/${languageCode}/user-dashbord/earnings`} activeClassName="active" aria-hidden="true">{t('header.userToolTipOption3')}</NavLink>
              </li>
              <li>
                <NavLink to={`/${languageCode}/user-dashbord/advertisement`} activeClassName="active" aria-hidden="true">{t('header.userToolTipOption4')}</NavLink>
                <Link className="add-button" to={`/${languageCode}/user-dashbord/advertisement/add`} >
                  <svg aria-hidden="true" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink to={`/${languageCode}/user-dashbord/memberships`} activeClassName="active" aria-hidden="true">{t('header.userToolTipOption5')}</NavLink>
              </li>
              {/*
              <li>
                <NavLink to="/user-dashbord/post" activeClassName="active" aria-hidden="true">Եկամուտների հաշվիչ</NavLink>
              </li>
              */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

UserDashbordSidebar.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(UserDashbordSidebar);

