import React, { Component } from 'react';
import PostItem from './post'

//import { authHeader } from '../../_helpers';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API;


class RelatedPost extends Component {


  constructor(props) {
    super(props);
    const postId = props.id
    this.state = {
        relatedPosts:[],
        postId:postId
    }
  }
  _isMounted = false;
  UNSAFE_componentWillReceiveProps(nextProps) {
    let postId = nextProps.id
    this.setState({
      postId
    })

  }
  componentDidMount(){
      let currentComponent = this;
      let language = JSON.parse(localStorage.getItem('lang'))
              let languageCode = language.code
      axios.get(apiUrl+'/posts/related/'+ this.state.postId, {
        headers:{'lang' : languageCode}
      })
          .then(function(response) {
              const posts = response.data.success;
              currentComponent._isMounted = true
              currentComponent.setState({ relatedPosts:posts });
          });
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    if(this._isMounted) {
      var relatedPosts = this.state.relatedPosts;
      //console.log(relatedPosts)
    }else {
      return false
    }
    return (
      <div className="post-item-container clear">
        <PostItem count='4' posts={relatedPosts} />
      </div>
    );
  }
}

export default RelatedPost;
