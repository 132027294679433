import React, { Component } from 'react';
import DropboxChooser from 'react-dropbox-chooser'
import DropBoxIcon from '../../../Assets/images/svg/dropbox-icon.svg'


class Dropbox extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      selectedItem: [],
    }
  }

  onSuccess(files) {
    // let selectedItem = this.state.selectedItem
    // let selectedItems = selectedItem.concat(e);
    // this.setState({
    //   selectedItem:selectedItems
    // });

    this.props.onSuccess(files);
  }
  onCancel(e) {

  }
  render() {
    return (
      <DropboxChooser
          appKey={'prc03rhq8i2t0lu'}
          success={files => this.onSuccess(files)}
          cancel={() => this.onCancel()}
          multiselect={true}
          extensions={['text', 'documents', 'video','images']} >
          <div className="dropbox-button">
              <img alt='' src={DropBoxIcon} /><span>Dropbox</span>
          </div>
      </DropboxChooser>
    )
  }
}
export default Dropbox;
