import React, { Component } from "react";
import axios from "axios";
import { authHeader } from "../../_helpers";
import Footer from "../footerComponent/footer";
import { Redirect } from "react-router-dom";
import PostItem from "../post/post";
import Pagination from "react-js-pagination";
import MetaTags from "../metaTags";
import AdvertisementBlock from "../userDashbord/advertisement/advertisementBlock";

const apiUrl = process.env.REACT_APP_API;

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
      activePage: 1,
      totalCount: "",
      pp: 12,
      category: {},
      redirect: false,
      redirectUrl: ""
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCategoryPosts = this.handleCategoryPosts.bind(this);
  }
  _isMounted = false;
  _isLoaded = false;
  _isCategory = false;
  componentDidMount() {
    let currentComponent = this;
    currentComponent.handleCategoryPosts();
    currentComponent._isMounted = true;
    console.log();
    axios
      .get(
        apiUrl +
          "/categories/posts/" +
          this.props.match.params.id +
          "?childs=true",
        {
          headers: { lang: this.props.match.params.lang }
        }
      )
      .then(function(response) {
        const total = response.data.total;
        currentComponent._isMounted &&
          currentComponent.setState({ totalCount: total });
      });
    axios
      .get(apiUrl + "/categories/getbyurlkey/" + this.props.match.params.id, {
        headers: { lang: this.props.match.params.lang }
      })
      .then(function(response) {
        const category = response.data;
        if (currentComponent._isMounted && category.urlKey === undefined) {
          let language = JSON.parse(localStorage.getItem("lang"));
          let languageCode = language.code;
          currentComponent.setState({
            redirect: true,
            redirectUrl: "/" + languageCode + "/not-found"
          });
        } else {
          currentComponent._isCategory = true;
          currentComponent._isCategory &&
            currentComponent.setState({ category: category });
        }
      });
  }

  handleCategoryPosts(pageNumber) {
    let currentComponent = this;
    const queryParams = {};
    let PostPerPage = currentComponent.state.pp;
    if (pageNumber !== undefined && pageNumber !== "") {
      queryParams.p = pageNumber;
    }
    queryParams.pp = PostPerPage;
    axios
      .get(
        apiUrl +
          "/categories/posts/" +
          this.props.match.params.id +
          "?childs=true",
        { params: queryParams, headers: authHeader() }
      )
      .then(function(response) {
        const posts = response.data.posts;

        currentComponent.setState({ post: posts, activePage: pageNumber });
      });
  }
  handlePageChange(pageNumber) {
    this.handleCategoryPosts(pageNumber);
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._isLoaded = false;
    this._isCategory = false;
  }

  countryOptions() {
    var postData = this.state.post;

    var newMainArr = [];
    var j = 1;
    if (postData.length < 4) {
      newMainArr.push(postData);
    } else {
      postData.forEach(function(value, index) {
        if (((j - 1) * 4 < index && (j - 1) * 4 === index) < j * 4) {
          if (index + 1 === j * 4) {
            newMainArr.push(postData.slice((j - 1) * 4, index + 1));
            j++;
            if (j * 4 > postData.length) {
              newMainArr.push(postData.slice(index + 1));
            }
          }
        }
      });
    }

    this._isLoaded = true;
    return newMainArr;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    if (this._isCategory === false) {
      return (
        <div className="loader-box">
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
        </div>
      );
    }
    // console.log(this.props)
    var data = this.countryOptions();
    // console.log(this.state);
    return (
      <div>
        <MetaTags />
        <div className="category-container">
          <div className="wrapper">
            <h1 className="section-title">{this.state.category.title}</h1>
            <div className="post-item-container clear">
              {data.map((list, key) => {
                return (
                  <div key={key} className="post-item-wrap clear">
                    <PostItem
                      categoryId={this.state.category.id}
                      posts={list}
                    />

                    {key === 0 ? (
                      <AdvertisementBlock
                        category={this.state.category.id}
                        key={key}
                        type="category-inner"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
            {this.state.pp < this.state.totalCount ? (
              <Pagination
                innerClass="pagination clear"
                hideFirstLastPages
                pageRangeDisplayed={5}
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pp}
                totalItemsCount={this.state.totalCount}
                onChange={this.handlePageChange}
                nextPageText={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 243.01 414.68"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="cls-1"
                          d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                        />
                      </g>
                    </g>
                  </svg>
                }
                prevPageText={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 243.01 414.68"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="cls-1"
                          d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Category;
