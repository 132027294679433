import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';
import Moment from 'react-moment';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import PostCountFunc from './postCountFunc'


import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
const imageUrl = process.env.REACT_APP_IMAGE_URL;
class PostItemList extends Component {
constructor(props) {
  super(props);
  const posts = props.posts
  const category = props.category

  this.state = {
    data:posts,
    category:category
  }

}

UNSAFE_componentWillReceiveProps(nextProps) {
  let data = nextProps.posts
  this.setState({
    data
  })


}

  render(){
    const listItem  = this.state.data
    //console.log(listItem)
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    const { t } = this.props;

    return (
      listItem.map((list , key) => {
        //const date = new Date(list.createdAt)
        const dateToFormat = list.createdAt;
          // console.log(list)
        return(
          <div key={key} className="post-item clear">
            <Link className="permalink" to={'/'+languageCode+'/post/'+list.urlKey}></Link>

            <div>
              <div className="image-box" style={{ backgroundImage: `url(${imageUrl+list.thumbnail})` }} >
                {/*<img alt="" src={imageUrl+list.thumbnail}/> */}
                {list.isSponsored ?
                  <div className="sponsored-label">{t('myPostPage.publishedSection.sponsoredLableText')}</div>
                  :
                  ''
                }
              </div>
              <div className="text-box">
                <Link to={'/'+languageCode+'/category/'+list.category.urlKey} className="category-name">{list.category.title}</Link>
                <div className="box clear">
                  <div className="date">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.99 299.99"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0Zm64.85,178.52H151.25c-.21,0-.41-.05-.63-.06s-.41.06-.63.06a10.37,10.37,0,0,1-10.37-10.37V62.25a10.38,10.38,0,0,1,20.75,0v95.53h54.47a10.37,10.37,0,0,1,0,20.74Z"/></g></g></svg>
                    <Moment format="DD.MM.YYYY" date={dateToFormat} />
                  </div>
                  <PostCountFunc viewCount={list.views} />
                </div>
                <div className="autor">
                  <Link to={'/'+languageCode+'/user/'+list.user.id} title={list.user.firstName+ ' ' + list.user.lastName}>
                    <ResponsiveEllipsis
                      text={list.user.firstName+ ' ' + list.user.lastName}
                      maxLine='1'
                      ellipsis='...'
                    />
                  </Link>
                </div>
                <div className="title" title={list.title}>
                  <ResponsiveEllipsis
                    text={list.title}
                    maxLine='4'
                    ellipsis='...'
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }

}

PostItemList.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(PostItemList);

